import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-animation-coffee-error',
  template: '<ng-lottie [options]="options"  width="300px" height="300px" />',
  standalone: true,
  imports: [LottieComponent],
})
export class AnimationCoffeeErrorComponent {
  options: AnimationOptions = {
    path: '../../../../../assets/animations/coffee-error.json',
  };
}
