import { Component, Input } from '@angular/core';
import { FunctionsDateService } from '../../utils/functions.date.service';

@Component({
  selector: 'app-input-month-year',
  templateUrl: './input-month-year.component.html',
  styleUrls: ['./input-month-year.component.css'],
})
export class InputMonthYearComponent {
  @Input() public title: string;
  @Input() public month: string;
  @Input() public year: string;

  constructor (
    public functionsDateService: FunctionsDateService
  ) {}
}
