import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContratosFiltrosService } from '../../../services/contrato-exp-filtros.service';
import { StatusEmbarque } from 'src/app/enums';
import { ContratosService } from '../../../services/contrato-exp.service';
import { IListContratos } from 'src/app/interfaces/ListContratos';
import { NgxTranslateService } from 'src/app/shared/services/translate/ngxTranslate.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-filtro-contratos',
  templateUrl: './filtro-contratos.component.html',
  styleUrls: ['./filtro-contratos.component.css'],
})
export class FiltroContratosComponent implements OnChanges {
  @Input() contratos: IListContratos[] = [];
  @Output() aplicarFiltro: EventEmitter<string> = new EventEmitter();

  private contratosFiltrosService = inject(ContratosFiltrosService);
  private contratosService = inject(ContratosService);
  private ngxTranslateService = inject(NgxTranslateService);
  private toastService = inject(ToastService);

  public limparTodosFiltros = false;
  public fecharTodosFiltros = false;
  public statusEmbarque = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contratos'].firstChange) return;
    if (
      changes['contratos'].currentValue !== changes['contratos'].previousValue
    ) {
      this.limparFiltros();
    }
  }

  public statusEmbarqueChange(statusEmbarque: StatusEmbarque[]) {
    const status =
      this.contratosService.retornarStatusEmbarquePtBR(statusEmbarque);
    this.contratosFiltrosService.mudarFiltro('status', status);
  }

  public importadoresChange(importadores: string[]) {
    const importadoresString = importadores.join(',');
    this.contratosFiltrosService.mudarFiltro(
      'importadores',
      importadoresString
    );
  }

  public dataEmbarqueChange(datas: { dataInicial: Date; dataFinal: Date }) {
    this.contratosFiltrosService.mudarFiltro(
      'data_embarque_inicial',
      datas.dataInicial.toISOString()
    );
    this.contratosFiltrosService.mudarFiltro(
      'data_embarque_final',
      datas.dataFinal.toISOString()
    );
  }

  public aplicarFiltros() {
    this.fecharTodosFiltros = true;
    setTimeout(() => {
      const filtros = this.contratosFiltrosService.getFiltros();
      if (filtros.data_embarque_inicial > filtros.data_embarque_final) {
        const validacao = this.ngxTranslateService.instant(
          'FORM_INFO_CONTRATO.BOARDING_DATE_VALIDATION'
        );
        this.fecharTodosFiltros = false;
        this.toastService.showToast(validacao, 'danger');
        return;
      }
      const stringFiltros = JSON.stringify(
        this.contratosFiltrosService.getFiltros()
      );
      this.fecharTodosFiltros = false;
      this.aplicarFiltro.emit(stringFiltros);
    }, 50);
  }

  public limparFiltros() {
    this.limparTodosFiltros = true;
    this.fecharTodosFiltros = true;
    setTimeout(() => {
      this.contratosFiltrosService.limparFiltros();
      this.limparTodosFiltros = false;
      this.fecharTodosFiltros = false;
      const filtros = JSON.stringify(this.contratosFiltrosService.getFiltros());
      this.aplicarFiltro.emit(filtros);
    }, 50);
  }
}
