<div class="modal-overlay h-container center-row">
  <div class="d-flex flex-column gap-3">
    <button
      *ngFor="let language of languages"
      class="dropdown-item dropdown-lang-item"
      (click)="languageChange(language)"
    >
      <div
        *ngIf="language === enumLanguage.INGLES"
        class="center-row-start gap-2"
      >
        <img src="/assets/eng.png" height="20" alt="English" />
        <span class="column-footer">{{
          'COMMON.ENGLISH' | translate | uppercase
        }}</span>
      </div>
      <div
        *ngIf="language === enumLanguage.PORTUGUES"
        class="center-row-start gap-2"
      >
        <img src="/assets/pt-br.png" height="20" alt="Portuguese" />
        <span class="column-footer">{{
          'COMMON.PORTUGUESE' | translate | uppercase
        }}</span>
      </div>
      <div
        *ngIf="language === enumLanguage.ESPANHOL"
        class="center-row-start gap-2"
      >
        <img src="/assets/spa.png" height="20" alt="Spanish" />
        <span class="column-footer">{{
          'COMMON.SPANISH' | translate | uppercase
        }}</span>
      </div>
    </button>
  </div>
</div>
