// Importações omitidas para brevidade

import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-logout',
  template: '', // Pode ser uma página vazia ou uma mensagem de logout
})
export class LogoutComponent implements OnInit {
  private router = inject(Router);
  private authService = inject(AuthService);

  constructor() {}

  ngOnInit(): void {
    this.authService.removeAuthToken();
    window.location.href = environment.logoutCognitoAddress;
    // localStorage.clear();
    // this.router.navigate([CallBackConstants.CALL_BACK_REDIRECT_ROOT]);
  }
}
