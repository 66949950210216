<app-group-box [title]="'FORM_INFO_CONTRATO_DOIS.MARKING' | translate">
  <div class="row">
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_CONTRATO_DOIS.STANDARD_BRAND' | translate
        }}</mat-label>
      <input disabled=true readonly class="input-form-dgs" type="text" data-testid="marcaPadrao" name="marcaPadrao"
        [ngModel]="contrato.marcaPadrao ?? ''" placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.STANDARD_BRAND' | translate
          )
        }}" />
    </div>
  </div>
</app-group-box>