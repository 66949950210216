import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratoExpViewComponent } from './contrato-exp-view/contrato-exp-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { ContratoExpMaterialModule } from 'src/app/shared/material/contrato-exp-material.module';
import { ContratoExpRoutingModule } from './contrato-exp-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntlCustom } from 'src/app/shared/components/custom-mat-paginator/custom-mat-paginator.component';
import { ContratosEditModalComponent } from './contrato-exp-modal/contrato-exp-modal.component';
import { ContratoExpModalContratoDoisModule } from './contrato-exp-modal/components/contrato-exp-modal-contrato-dois/contrato-exp-modal-contrato-dois.module';
import { MatDividerModule } from '@angular/material/divider';
import { ContratoExpModalInstrucaoEmbarqueModule } from './contrato-exp-modal/components/contrato-exp-modal-instrucao-embarque/contrato-exp-modal-instrucao-embarque.module';
import { ContratoExpModalReservaCertificadoModule } from './contrato-exp-modal/components/contrato-exp-modal-reserva-certificado/contrato-exp-modal-reserva-certificado.module';
import { ContratoExpModalTransporteModule } from './contrato-exp-modal/components/contrato-exp-modal-transporte/contrato-exp-modal-transporte.module';
import { ContratoExpModalFaturamentoModule } from './contrato-exp-modal/components/contrato-exp-modal-faturamento/contrato-exp-modal-faturamento.module';
import { FiltroDataEmbarqueComponent } from './contrato-exp-view/components/filtro-data-embarque/filtro-data-embarque.component';
import { ContratoExpModalContratoUmModule } from './contrato-exp-modal/components/contrato-exp-modal-contrato-um/contrato-exp-modal-contrato-um.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltroContratosComponent } from './contrato-exp-view/components/filtro-contratos/filtro-contratos.component';
import { FiltroImportadoresComponent } from './contrato-exp-view/components/filtro-importadores/filtro-importadores.component';
import { FiltroStatusEmbarqueComponent } from './contrato-exp-view/components/filtro-status-embarque/filtro-status-embarque.component';
@NgModule({
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom }],
  declarations: [
    ContratoExpViewComponent,
    ContratosEditModalComponent,
    FiltroDataEmbarqueComponent,
    FiltroContratosComponent,
    FiltroImportadoresComponent,
    FiltroStatusEmbarqueComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    ContratoExpMaterialModule,
    ContratoExpRoutingModule,
    SharedModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    DragDropModule,
    MatIconModule,
    ToastrModule.forRoot(),
    TranslateModule,
    MatDividerModule,
    ContratoExpModalContratoDoisModule,
    ContratoExpModalInstrucaoEmbarqueModule,
    ContratoExpModalReservaCertificadoModule,
    ContratoExpModalTransporteModule,
    ContratoExpModalFaturamentoModule,
    ContratoExpModalContratoUmModule,
  ],
  exports: [TranslateModule],
})
export class ContratoExpModule {}
