<div class="lbl-status-emb-ellipsis">
  <mat-label class="lbl lbl-status-emb">{{
    'FORMS.SHIPMENT_STATUS' | translate
  }}</mat-label>
</div>
<mat-form-field>
  <mat-select
    #select
    [formControl]="statusEmbarqueForm"
    multiple
    placeholder="{{ 'COMMON.ALL' | translate }}"
  >
    <mat-option
      *ngFor="let option of options"
      [value]="option"
      (click)="toggleItem(option.value === 'X')"
    >
      {{ option.viewValue | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
