import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from 'src/app/enums/Language';

@Injectable({
  providedIn: 'root',
})
export class NgxTranslateService {
  translateService = inject(TranslateService);

  private browserLang = this.getEnumKey(this.getBrowserLanguage());
  private currentLang$ = new BehaviorSubject(Language[this.browserLang]);
  private currentLang = this.currentLang$.asObservable();

  public getBrowserLanguage(): string {
    return this.translateService.getBrowserLang();
  }

  public getEnumKey(lang): string {
    const enumKey =
      Object.keys(Language)[
        Object.values(Language).indexOf(lang as unknown as Language)
      ];
    if (!enumKey) {
      return 'PORTUGUES';
    }
    return enumKey;
  }

  public getCurrentLanguage(): Observable<Language> {
    return this.currentLang;
  }

  public setCurrentLanguage(language: Language): void {
    this.setLangNgx(language);
  }

  public getLanguages(): Language[] {
    return [Language.PORTUGUES, Language.INGLES, Language.ESPANHOL];
  }

  private setLangNgx(language: Language): void {
    const matchLanguage = /pt|en|es/;
    const matchedLanguage = language.match(matchLanguage)
      ? language
      : Language.PORTUGUES;
    this.translateService.use(matchedLanguage);
    this.translateService.setDefaultLang(matchedLanguage);
    this.currentLang$.next(matchedLanguage);
  }

  public instant(key: string | string[], interpolateParams?: any): any {
    return this.translateService.instant(key, interpolateParams);
  }
}
