import { Component, Input, inject } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { IOics } from 'src/app/interfaces/Oics';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-dois-oics',
  templateUrl: './contrato-exp-modal-contrato-dois-oics.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-dois-oics.component.css'],
})
export class ContratoExpModalContratoDoisOicsComponent {
  @Input() contrato: IContrato = {} as IContrato;
  @Input() oics: IOics[] = [];
  public displayedColumns = ['num_oic', 'ano', 'sacas', 'kg', 'contramarca'];

  tableDragDrop = inject(TableDragDropService);
}
