import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';

@Component({
  selector: 'app-contrato-exp-modal-contrato-um',
  templateUrl: './contrato-exp-modal-contrato-um.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-um.component.css'],
})
export class ContratoExpModalContratoUmComponent {
  @Input() contrato: IContrato = {} as IContrato;
}
