import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-animation-access-denied',
  template: '<ng-lottie [options]="options"  width="300px" height="300px" />',
  standalone: true,
  imports: [LottieComponent],
})
export class AnimationAccessDeniedComponent {
  options: AnimationOptions = {
    path: '../../../../../assets/animations/access-denied.json',
  };
}
