<app-group-box [title]="'CONTRACT_EXPORTATION_MODAL.TAB_CONTRACT_I' | translate">
    <div class="row g-4">
        <div class="col-12 col-md-12 col-lg">
            <div class="row g-3">
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.CLIENT_IMPORTER' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.CLIENT_IMPORTER' | translate
                  )
                }}" class="input-form-dgs" [ngModel]="contrato.nomImportador" type="text"  
                data-testid="clientImporter"
                id="clientImporter"
                        name="nomImportador" readonly />
                </div>
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.REF_CLI' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.REF_CLI' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="clientRef"
                id="clientRef" name="clientRef" [ngModel]="contrato.refCliente"
                        readonly />
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.BOARDING_PERIOD' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                    'FORM_INFO_CONTRATO.BOARDING_PERIOD_PLACEHOLDER' | translate
                }}" class="input-form-dgs" type="text" 
                data-testid="seagoingService"
                id="seagoingService" name="seagoingService"
                        [ngModel]="contrato.periodoEmb | translateMonthYear" readonly />
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.SAMPLE' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.SAMPLE' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="sample"
                id="sample" name="sample" [ngModel]="contrato.tipoAmostra"
                        readonly />
                </div>
                <div class="col-6 col-md-6 col-lg-2">
                    <mat-label class="d-block lbl mb-1 invisivel">.</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.NUMBER' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="numAmostra"
                id="numAmostra" name="numAmostra"
                        [ngModel]="contrato.numAmostra" readonly />
                </div>
                <div class="col-6 col-md-6 col-lg-2">
                    <mat-label class="d-block lbl mb-1 invisivel">.</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.YEAR' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="numAnoAmostra"
                id="numAnoAmostra" name="numAnoAmostra"
                        [ngModel]="contrato.numAnoAmostra" readonly />
                </div>
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.VENDOR' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.VENDOR' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="vendor"
                id="vendor" name="vendor" [ngModel]="contrato.nomVendedor"
                        readonly />
                </div>
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.SHIPPER' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.SHIPPER' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="shipper"
                id="shipper" name="shipper" readonly
                        [ngModel]="contrato.nomEmbarcador" />
                </div>
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.CERTIFIER' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.CERTIFIER' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="certifier"
                id="certifier" readonly name="certifier"
                        [ngModel]="contrato.nomCertificador" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.TRANSACTION' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.TRANSACTION' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="transaction"
                id="transaction" name="transaction"
                        [ngModel]="contrato.transacao" readonly />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.TYPE_OF_COFFEE' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.TYPE_OF_COFFEE' | translate
                  )
                }}" class="input-form-dgs" [ngModel]="contrato.tipoCafe" type="text" 
                data-testid="typeOfCoffee"
                id="typeOfCoffee"
                        name="typeOfCoffee" readonly />
                </div>
            </div>
        </div>

        <div class="width-vr d-lg-block d-none">
            <app-divider orientation="V" />
        </div>
        <div class="d-lg-none">
            <app-divider orientation="H" />
        </div>

        <div class="col-12 col-md-12 col-lg">
            <div class="row g-3">
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.SPECIE' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.SPECIE' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="specie"
                id="specie" name="specie" readonly
                        [ngModel]="contrato.nomEspecieCafe" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.SIEVE' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.SIEVE' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="sieve"
                id="sieve" name="sieve" readonly
                        [ngModel]="contrato.nomPeneira" />
                </div>
                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.STANDARD' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.STANDARD' | translate
                  )
                }}" class="input-form-dgs" type="text" 
                data-testid="standard"
                id="standard" name="standard" readonly
                        [ngModel]="contrato.nomPadrao" />
                </div>

                <div class="col-12">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.DSC_QUALITY' | translate
                        }}</mat-label>
                    <input disabled="true" placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.DSC_QUALITY' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="descriptionQuality" id="descriptionQuality" name="descriptionQuality" readonly
                        [ngModel]="contrato.dscQualidade" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.QUANTITY_BAGS' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.QUANTITY_BAGS' | translate
                  )
                }}" class="input-form-dgs text-end" type="text" data-testid="quantityBags" id="quantityBags" name="quantityBags" readonly
                        [ngModel]="contrato.qtdSacas | localizedNumber" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.WEIGHT_BAGS' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.WEIGHT_BAGS' | translate
                  )
                }}" class="input-form-dgs text-end" type="text" data-testid="weightBags" id="weightBags" name="weightBags" readonly
                        [ngModel]="contrato.qtdPadraoSaca | localizedNumber" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.POUND' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.POUND' | translate
                  )
                }}" class="input-form-dgs text-end" type="text" data-testid="pound" id="pound" name="pound" readonly
                        [ngModel]="contrato.numSacaLibra | localizedNumber" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.TONS' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.TONS' | translate
                  )
                }}" class="input-form-dgs text-end" type="text" data-testid="tons" id="tons" name="tons" readonly
                        [ngModel]="contrato.numToneladas | localizedNumber" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.KG' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.KG' | translate
                  )
                }}" class="input-form-dgs text-end" type="text" data-testid="kg" id="kg" name="kg" readonly
                        [ngModel]="contrato.qtdKg | localizedNumber" />
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.INCOTERM' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'FORM_INFO_CONTRATO.INCOTERM' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="incoterm" id="incoterm" name="incoterm" readonly
                        [ngModel]="contrato.incoterm" />
                </div>
                <div class="col-6 col-md-3 col-lg-3">
                    <mat-label class="d-block lbl mb-1">{{
                        'FORM_INFO_CONTRATO.COFFEE_CROPS' | translate
                        }}</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.YEAR' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="ano1Safra1" id="ano1Safra1" name="coffeeCrops" readonly
                        [ngModel]="contrato.ano1Safra1" />
                </div>
                <div class="col-6 col-md-3 col-lg-3">
                    <mat-label class="d-block lbl mb-1 invisivel">.</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.YEAR' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="ano1Safra2" id="ano1Safra2" name="coffeeCrops" readonly
                        [ngModel]="contrato.ano1Safra2" />
                </div>
                <div class="col-6 col-md-3 col-lg-3">
                    <mat-label class="d-block lbl mb-1 invisivel">.</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.YEAR' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="ano2Safra1" id="ano2Safra1" name="coffeeCrops" readonly
                        [ngModel]="contrato.ano2Safra1" />
                </div>
                <div class="col-6 col-md-3 col-lg-3">
                    <mat-label class="d-block lbl mb-1 invisivel">.</mat-label>
                    <input disabled=true placeholder="{{
                  functionsDateService.capitalizeFirstLetter(
                    'COMMON.YEAR' | translate
                  )
                }}" class="input-form-dgs" type="text" data-testid="ano2Safra2" id="ano2Safra2" name="coffeeCrops" readonly
                        [ngModel]="contrato.ano2Safra2" />
                </div>
            </div>
        </div>
    </div>

</app-group-box>