import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { StatusEmbarque } from 'src/app/enums';

export type SelectStatusEmbarque = {
  value: string;
  viewValue: string;
};

@Component({
  selector: 'contratos-filtro-status-embarque',
  templateUrl: './filtro-status-embarque.component.html',
  styleUrls: ['./filtro-status-embarque.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FiltroStatusEmbarqueComponent implements OnChanges {
  @Input() statusEmbarque: string[] = [];
  @Input() limparSelecoes: boolean = false;
  @Input() fecharSelecoes: boolean = false;
  @Output() statusEmbarqueChange = new EventEmitter<StatusEmbarque[]>();
  @ViewChild('select') select: MatSelect;

  public statusEmbarqueForm = new FormControl();
  public todosSelecionados: boolean = false;

  public ngOnChanges() {
    if (this.limparSelecoes) {
      this.deselecionarTodos();
      this.todosSelecionados = false;
    }
    if (this.fecharSelecoes) {
      this.select.close();
      this.fecharSelecoes = false;
    }
  }

  public options: SelectStatusEmbarque[] = [
    { value: 'X', viewValue: 'FLAGS.All' },
    {
      value: StatusEmbarque.A_EMBARCAR,
      viewValue: 'FLAGS.To_Ship',
    },
    {
      value: StatusEmbarque.EMBARCADO,
      viewValue: 'FLAGS.Shipped',
    },
    {
      value: StatusEmbarque.TRANSITO,
      viewValue: 'FLAGS.In_Transit',
    },
    {
      value: StatusEmbarque.ALFANDEGADO,
      viewValue: 'FLAGS.Bonded',
    },
  ];

  public toggleAllSelection() {
    this.todosSelecionados ? this.deselecionarTodos() : this.selecionarTodos();
    this.emitEventSelect();
  }

  public toggleItem(todos: boolean) {
    if (todos) {
      this.toggleAllSelection();
      return;
    }
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.todosSelecionados = newStatus;
    if (this.itemAllSelecionado()) this.toggleAllSelection();
    if (!this.todosSelecionados) this.select.options.first.deselect();
    this.emitEventSelect();
  }

  private itemAllSelecionado(): boolean {
    return this.select.options
      .toArray()
      .slice(1)
      .every((item: MatOption) => item.selected);
  }

  public selecionarTodos() {
    this.select.options.forEach((item: MatOption) => item.select());
    this.todosSelecionados = true;
  }

  public deselecionarTodos() {
    this.select.options.forEach((item: MatOption) => item.deselect());
    this.todosSelecionados = false;
  }

  private emitEventSelect() {
    const status = this.statusEmbarqueForm.value
      .filter((item: SelectStatusEmbarque) => item.value !== 'X')
      .map((item: SelectStatusEmbarque) => item.value);
    this.statusEmbarqueChange.emit(status);
  }
}
