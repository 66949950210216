/* eslint-disable no-constant-condition */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StatusEmbarque } from 'src/app/enums';
import { TipoUsuario } from 'src/app/enums/TipoUsuario';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { IListContratos } from 'src/app/interfaces/ListContratos';
import { RespostaPadrao } from 'src/app/interfaces/RespostaPadrao';
import { FiliaisService } from 'src/app/shared/services/Filiais/filiais.service';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';
import { AuthService } from '../../../auth/services/auth.service';
import { ControlAccessService } from '../../../auth/services/control-access.service';
import { IResponseTotalizarSacas } from '../contrato-exp-view/interfaces/ResponseTotalizarSacas';

@Injectable({
  providedIn: 'root',
})
export class ContratosService {
  public tokenReq: string;

  constructor(
    private http: HttpClient,
    private functionsDateService: FunctionsDateService,
    private controlAccessService: ControlAccessService,
    private authService: AuthService,
    private filiasService: FiliaisService
  ) {
    this.tokenReq = localStorage.getItem('token-orchestrator');
  }

  fetchListContratos(codFilial: number): Observable<IListContratos[]> {
    const nomEmpresa = encodeURIComponent(localStorage.getItem('Empresa'));
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.tokenReq}`)
      .set('Empresa', nomEmpresa);

    return from(this.controlAccessService.getCompanyUrlBackend()).pipe(
      switchMap((base_url) => {
        let url = `${base_url}/api/contratos?codEmpresa=1`;

        url += `&codFilial=${codFilial}`;

        Object.values(StatusEmbarque).forEach(
          (status: string) => (url += `&statusEmb=${status}`)
        );

        return from(
          Promise.all([
            this.authService.getTypeUser(),
            this.authService.getArrayCodVinculos(),
          ])
        ).pipe(
          switchMap(([typeUser, arrayCodVinculos]) => {
            if (typeUser === TipoUsuario.PORTAL) {
              arrayCodVinculos.forEach((cod_vinculo) => {
                url += `&codImportadores=${cod_vinculo}`;
              });
            }

            return this.http.get<RespostaPadrao<any>>(url, { headers }).pipe(
              map((response) => {
                if (response.success) {
                  const contratos = response.data.map((data) => {
                    return {
                      ...data,
                      dataEmbarque:
                        this.functionsDateService.convertStringToDate(
                          data.embarque
                        ),
                    };
                  });
                  return contratos;
                } else {
                  throw new Error(response.message);
                }
              }),
              catchError(() => of([]))
            );
          })
        );
      })
    );
  }

  fetchDadosContrato(handleContrato: number): Observable<IContrato[]> {
    const nomEmpresa = encodeURIComponent(localStorage.getItem('Empresa'));
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.tokenReq}`)
      .set('Empresa', nomEmpresa);

    return from(this.controlAccessService.getCompanyUrlBackend()).pipe(
      switchMap((base_url) => {
        const url = `${base_url}/api/dados-contrato?handle=${handleContrato}`;

        return this.http
          .get<RespostaPadrao<IContrato[]>>(url, { headers })
          .pipe(
            map((response) => {
              if (response.success) {
                const dadosContrato = response.data;
                return dadosContrato;
              } else {
                throw new Error(response.message);
              }
            }),
            catchError(() => {
              return of([]);
            })
          );
      })
    );
  }

  public totalizarSacas(
    dataSource: MatTableDataSource<IListContratos>
  ): IResponseTotalizarSacas {
    try {
      const totalSacas = dataSource.filteredData.reduce(
        (accumulator, current) => {
          if (isNaN(accumulator)) return NaN;
          const stringSacas = current.Sacas;
          const semSeparadorMilhar = stringSacas.replace(/\./g, '');
          const comPontoDecimal = semSeparadorMilhar.replace(',', '.');
          const sacas = parseFloat(comPontoDecimal);
          if (isNaN(sacas)) return NaN;
          return accumulator + sacas;
        },
        0
      );

      if (isNaN(totalSacas))
        return {
          label: 'FOOTER_CONTRACT_TABLE.FAIL_TOTAL_BAGS',
        };
      return {
        label: 'FOOTER_CONTRACT_TABLE.TOTAL_PER_BAGS',
        totalSacas: totalSacas,
      };
    } catch (error) {
      return {
        label: 'FOOTER_CONTRACT_TABLE.FAIL_TOTAL_BAGS',
      };
    }
  }

  public retornarStatusEmbarquePtBR(statusEmbarque: StatusEmbarque[]) {
    let status = '';
    statusEmbarque.forEach((item) => {
      if (item === StatusEmbarque.ALFANDEGADO) {
        status += 'ALFANDEGADO,';
      } else if (item === StatusEmbarque.A_EMBARCAR) {
        status += 'A EMBARCAR,';
      } else if (item === StatusEmbarque.EMBARCADO) {
        status += 'EMBARCADO,';
      } else {
        status += 'EM TRÂNSITO,';
      }
    });
    return status;
  }
}
