import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Language } from 'src/app/enums';
import { NgxTranslateService } from 'src/app/shared/services/translate/ngxTranslate.service';

@Component({
  selector: 'modal-select-language',
  templateUrl: './modal-select-language.component.html',
  styleUrls: ['./modal-select-language.component.css'],
})
export class ModalSelectLanguageComponent {
  constructor(public dialogRef: MatDialogRef<ModalSelectLanguageComponent>) {}

  public translateService = inject(NgxTranslateService);
  public enumLanguage = Language;
  public languages: Language[];
  public currentLanguage$: Observable<Language>;

  ngOnInit(): void {
    this.currentLanguage$ = this.translateService.getCurrentLanguage();
    this.languages = this.translateService.getLanguages();
  }

  languageChange(language: Language) {
    this.translateService.setCurrentLanguage(language);
    this.dialogRef.close();
  }
}
