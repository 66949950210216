export const environment = {
  dev: true,
  local: false,
  usuarioLocal: 'fernanda',
  empresaLocal: 'DIGISUL EXPORTADORA LTDA',
  apiUrl: 'https://tgm4stiz84.execute-api.us-east-1.amazonaws.com/v1/homolog',
  apiOrchestratorUrl:
    'https://56w6kiobv0.execute-api.us-east-1.amazonaws.com/v1/homolog',
  secret: 'rA+6L/fsKC9j0c4a3IPjMMW4/ovM9VmvFQ+VTtG2fO8=',
  cognitoAddress:
    'https://portal-homolog.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=48170hla99dtfpqes58mqcu93u&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fd36wjwwugsr45f.cloudfront.net%2Fcallback',
  logoutCognitoAddress:
    'https://portal-homolog.auth.us-east-1.amazoncognito.com/logout?client_id=48170hla99dtfpqes58mqcu93u&logout_uri=https://portal-homolog.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=48170hla99dtfpqes58mqcu93u&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fd36wjwwugsr45f.cloudfront.net%2Fcallback',
  whatsappToggle: false,
  authToggle: true,
  dadosContratoToggle: true,
  contratoDoisToggle: true,
  instrucoesEmbarqueToggle: true,
  reservaCertificadoToggle: true,
  transporteToggle: true,
  faturamentoToggle: true,
  filtroData: true,
  dashboardsToggle: true,
  sideNavToggle: true,
  logoToggle: true,
};
