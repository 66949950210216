import { Component, Input, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Certificado } from 'src/app/interfaces/Certificado';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';

@Component({
  selector: 'app-contrato-exp-modal-reserva-certificado-certificados',
  templateUrl:
    './contrato-exp-modal-reserva-certificado-certificados.component.html',
  styleUrls: [
    './contrato-exp-modal-reserva-certificado-certificados.component.css',
  ],
})
export class ContratoExpModalReservaCertificadoCertificadosComponent {
  @Input() certificados: Certificado[] = [];

  reservaCertificadoToggle = environment.reservaCertificadoToggle;
  public displayedColumns = ['nomCertificado'];

  tableDragDropService = inject(TableDragDropService);
}
