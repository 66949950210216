<app-loading [isLoading]="loading"></app-loading>

<div
  class="h-100 d-flex flex-lg-row flex-column justify-content-center align-items-center gap-4"
>
  <div class="order-lg-1 order-2 d-flex flex-column gap-4">
    <div class="title-container">
      <h1>{{ 'PAGE_ERROR.TITLE' | translate }}</h1>
    </div>
    <div class="subtitle-container">
      <span>{{ 'PAGE_ERROR.SUBTITLE' | translate }}</span>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <button mat-flat-button (click)="retryLastRequest()">
        <mat-icon>sync</mat-icon>
        {{ 'COMMON.RELOAD' | translate }}
      </button>
    </div>
  </div>
  <div
    class="order-lg-2 order-1 h-100 d-flex justify-content-center align-items-center"
  >
    <div class="animation-container">
      <app-animation-coffee-error />
    </div>
  </div>
</div>
