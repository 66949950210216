import { Component, Input } from '@angular/core';
import { TipoPrecoFixacao } from 'src/app/enums/TipoPrecoFixacao';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-dois-fixacao',
  templateUrl: './contrato-exp-modal-contrato-dois-fixacao.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-dois-fixacao.component.css'],
})
export class ContratoExpModalContratoDoisFixacaoComponent {  
  @Input() contrato: IContrato = {} as IContrato;
  
  constructor (
    public functionsDateService: FunctionsDateService
  ) {}

  public tipoPrecoFixacao = TipoPrecoFixacao;

  public mes = this.contrato.mesAnoFixacao?.split('/')[0] ?? '';
  public ano = this.contrato.mesAnoFixacao?.split('/')[1] ?? '';

  
}
