<app-group-box
  [title]="'FORM_INFO_RESERVATION_TRANSPORT.BILL_OF_LANDING' | translate"
>
  <div class="row g-4">
    <div class="col-12 col-md-12 col-lg-8">
      <div class="row g-3">
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.BL_NUMBER' | translate
          }}</mat-label>

          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_RESERVATION_TRANSPORT.BL_NUMBER' | translate
              )
            }}"
            data-testid="numBl"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.numBl ?? '0'"
          />
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.BL_TYPE' | translate
          }}</mat-label>

          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_RESERVATION_TRANSPORT.BL_TYPE' | translate
              )
            }}"
            data-testid="tpBl"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.tpBl ?? ''"
          />
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.BL_DATE' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            
            data-testid="datBl"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.datBl ?? ''"
          />
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.RECEIPT_OF_BL' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            
            data-testid="datRecebBl"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.datRecebBl ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.COURIER_NUMBER' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_RESERVATION_TRANSPORT.COURIER_NUMBER' | translate
              )
            }}"
            data-testid="numCourier"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.numCourier ?? '0'"
          />
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.SEND_DOC' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            
            data-testid="datEnvioDoc"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.datEnvioDoc ?? ''"
          />
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.SHIP_ENTRY' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            
            data-testid="entNavio"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.entNavio ?? ''"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_RESERVATION_TRANSPORT.COURIER_NAME' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_RESERVATION_TRANSPORT.COURIER_NAME' | translate
              )
            }}"
            data-testid="nomCourier"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.nomCourier ?? ''"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-4">
      <div class="row">
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.NOTE' | translate
          }}</mat-label>
          <textarea
          disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.NOTE' | translate
              )
            }}"
            data-testid="obsBl"
            readonly
            class="input-form-dgs-textarea"
            type="text"
            [ngModel]="contrato.obsBl ?? ''"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</app-group-box>
