import { Component, inject, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-um-agent',
  templateUrl: './contrato-exp-modal-contrato-um-agent.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-um-agent.component.css'],
})
export class ContratoExpModalContratoUmAgentComponent {
  @Input() contrato: IContrato = {} as IContrato;

  constructor() {}

  public displayedColumns: string[] = [
    'codAgente',
    'nomAgente',
    'valComissao',
    'condicao',
    'valTotalComissao',
  ];

  tableDragDropTable = inject(TableDragDropService);
}
