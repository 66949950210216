<app-group-box [title]="'FORM_INFO_INVOICING.RECEIVABLES' | translate">
  <div class="table-container p-0">
    <table
      mat-table
      [dataSource]="contrato.recebimentos"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDropTable.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span data-testid="rowNotFound" class="column-footer">
              {{
                'FORM_INFO_INVOICING.RECEIVABLES_NOT_FOUND'
                  | translate
                  | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>
      <ng-container matColumnDef="data">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.DATE' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="fw-bold">
          {{ element.recebsData }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-valor">
              <app-icon-drag-drop />
              {{ 'COMMON.VALUE' | translate }} US$
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-valor">
            {{ element.recebsValorDolar | localizedNumber }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="observacao">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.NOTE' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.recebsDscObs }}
        </td>
      </ng-container>

      <ng-container matColumnDef="taxa_conv">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-taxa">
              <app-icon-drag-drop />
              {{ 'FORM_INFO_INVOICING.EXCHANGE_RATE' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-taxa">
            {{ element.recebsTaxaConv | localizedNumber }}
          </div>
        </td>
      </ng-container>

      <!-- Falta uma coluna "Valor R$" aqui-->

      <ng-container matColumnDef="conta">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.ACCOUNT' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.recebsConta }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="row mt-3">
    <div class="col-12 col-md-2 col-lg-3">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_INVOICING.TOTAL_DUE_US' | translate
      }}</mat-label>
      <input
        data-testid="recebTotalDueDolar"
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_INVOICING.TOTAL_DUE_US' | translate
          )
        }}"
        class="input-form-dgs text-end"
        type="text"
        readonly
        [ngModel]="contrato.recebTotalDueDolar | localizedNumber"
      />
    </div>
    <div class="col-12 col-md-2 col-lg-3">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_INVOICING.TOTAL_RECEIVED_US' | translate
      }}</mat-label>
      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_INVOICING.TOTAL_RECEIVED_US' | translate
          )
        }}"
        data-testid="recebValTotalDolar"
        class="input-form-dgs text-end"
        type="text"
        readonly
        [ngModel]="contrato.recebValTotalDolar | localizedNumber"
      />
    </div>
    <div class="col-12 col-md-2 col-lg-3">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_INVOICING.RECEIVABLE_BALANCE_US' | translate
      }}</mat-label>
      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_INVOICING.RECEIVABLE_BALANCE_US' | translate
          )
        }}"
        data-testid="recebSaldoReceber"
        class="input-form-dgs text-end"
        type="text"
        readonly
        [ngModel]="contrato.recebSaldoReceber | localizedNumber"
      />
    </div>
  </div>
</app-group-box>