import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FunctionsDateService {
  public convertStringToDate(stringData: string): Date {
    const meses = {
      JAN: 0,
      FEV: 1,
      MAR: 2,
      ABR: 3,
      MAI: 4,
      JUN: 5,
      JUL: 6,
      AGO: 7,
      SET: 8,
      OUT: 9,
      NOV: 10,
      DEZ: 11,
    };

    const [mes, ano] = stringData.split('/');
    const mesIndex = meses[mes.toUpperCase()];
    const dataMontada = new Date();
    dataMontada.setFullYear(Number(ano));
    dataMontada.setMonth(mesIndex);
    dataMontada.setDate(1);
    return dataMontada;
  }

  public capitalizeFirstLetter(str: string) {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  
  public getYearsBetweenDates(dataInicial: Date, dataFinal: Date): number[] {
    const anos = [];
    const anoInicial = dataInicial.getFullYear();
    const anoFinal = dataFinal.getFullYear();

    for (let year = anoInicial; year <= anoFinal; year++) {
      anos.push(year);
    }
    return anos;
  }
}
