import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class TableDragDropService {
  constructor() {}

  fixarTableDropped<T>(displayedColumns: T[], event: CdkDragDrop<T[]>) {
    moveItemInArray(displayedColumns, event.previousIndex, event.currentIndex);
  }
}
