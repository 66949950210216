import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    if (await this.authService.isAuthenticated()) {
      return true;
    } else {
      if (environment.authToggle) {
        window.location.href = environment.cognitoAddress;
      } else {
        return true;
      }
      return false;
    }
  }
}