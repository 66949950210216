import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-dois-notas',
  templateUrl: './contrato-exp-modal-contrato-dois-notas.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-dois-notas.component.css'],
})
export class ContratoExpModalContratoDoisNotasComponent {
  @Input() public contrato: IContrato = {} as IContrato;

  constructor (
    public functionsDateService: FunctionsDateService
  ) {}

}
