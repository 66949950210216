<app-group-box [title]="'FORM_INFO_CONTRATO_DOIS.FIXATION' | translate">
  <div class="d-flex flex-column w-100 gap-2">
    <div class="row">
      <div class="col d-flex justify-content-start gap-2">
        <span class="badge-dgs badge-dgs-default rounded-pill">
          {{
          contrato.flgPrecoFixacao === tipoPrecoFixacao.FIXO
          ? ('FORM_INFO_CONTRATO_DOIS.FIXED' | translate)
          : ('FORM_INFO_CONTRATO_DOIS.TO_FIX' | translate)
          }}
        </span>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.EXCHANGE' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_CONTRATO_DOIS.EXCHANGE' | translate
            )
          }}" readonly class="input-form-dgs" type="text" data-testid="nomBolsa" name="nomBolsa" [ngModel]="contrato.nomBolsa ?? ''" />
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.FIXING_OPTION' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.FIXING_OPTION' | translate
          )
        }}" readonly class="input-form-dgs" type="text" data-testid="opcaoFixacao" name="opcaoFixacao" [ngModel]="contrato.opcaoFixacao ?? ''" />
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <app-input-month-year [title]="'FORM_INFO_CONTRATO_DOIS.FIXATION_MONTH' | translate" [month]="mes"
          [year]="ano" />
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.DIFFERENTIAL' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_CONTRATO_DOIS.DIFFERENTIAL' | translate
            )
          }}" readonly class="input-form-dgs text-end" type="text" data-testid="numDiferencial" name="numDiferencial"
          [ngModel]="contrato.numDiferencial | localizedNumber" />
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.FIXED_VALUE' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_CONTRATO_DOIS.FIXED_VALUE' | translate
            )
          }}" readonly class="input-form-dgs text-end" type="text" data-testid="numValorFixado" name="numValorFixado"
          [ngModel]="contrato.numValorFixado ?? 0 | localizedNumber" />
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'COMMON.FINAL_VALUE' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'COMMON.FINAL_VALUE' | translate
            )
          }}" readonly class="input-form-dgs text-end" type="text" data-testid="numValorFinal" name="numValorFinal"
          [ngModel]="contrato.numValorFinal ?? 0 | localizedNumber" />
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-2 col-md-6 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.FIXATION_DATE' | translate
          }}</mat-label>
        <input disabled=true 
        readonly class="input-form-dgs" placeholder="dd/mm/yyyy" type="tetx" data-testid="dataFixacao" name="dataFixacao" [ngModel]="contrato.dataFixacao ?? ''" />
      </div>
      <div class="col-lg-2 col-md-6 col-sm-6">
        <mat-label class="d-block lbl lbl-status-emb m-1" [matTooltip]="
          'FORM_INFO_CONTRATO_DOIS.FND_DATE_TOOLTIP'
            | translate
        ">{{
          'FORM_INFO_CONTRATO_DOIS.FND_DATE' | translate
          }}</mat-label>
        <input disabled=true readonly class="input-form-dgs" placeholder="dd/mm/yyyy" type="text" data-testid="dataFnd" name="dataFnd" [ngModel]="contrato.dataFnd ?? ''" />
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.FIXING_POSITION' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_CONTRATO_DOIS.FIXING_POSITION' | translate
            )
          }}" readonly class="input-form-dgs input-posicao-fixacao" type="text" data-testid="posicaoFixacao" name="posicaoFixacao" [ngModel]="
            (contrato.qtdLotes ?? 0) +
            ' ' +
            ('COMMON.OF' | translate) +
            ' ' +
            (contrato.qtdLotesFixados ?? 0) +
            ' ' +
            ('FORM_INFO_CONTRATO_DOIS.FIXED_LOTS' | translate)
          " />
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12">
        <mat-label class="d-block lbl lbl-status-emb m-1">{{
          'FORM_INFO_CONTRATO_DOIS.INSTRUCTIONS_FOR_POSTING' | translate
          }}</mat-label>
        <div class="d-flex align-items-center gap-1">
          <input disabled=true placeholder="0" readonly class="input-form-dgs text-end" data-testid="qtdLotes"  type="number"
            [ngModel]="contrato.qtdLotes" />
          <mat-label class="lbl">{{ 'COMMON.OF' | translate }}</mat-label>
          <input disabled=true placeholder="0" readonly class="input-form-dgs text-end" data-testid="qtdLotesFixados" type="number"
            [ngModel]="contrato.qtdLotesFixados" />
          <div class="lbl-lotes-fixados">
            <mat-label class="lbl">{{
              'FORM_INFO_CONTRATO_DOIS.FIXED_LOTS' | translate
              }}</mat-label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-group-box>