<app-group-box [title]="'FORM_INFO_CONTRATO.BROKERS' | translate">
  <div class="table-container p-0">
    <table
      mat-table
      [dataSource]="contrato.agentes"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDropTable.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span data-testid="rowNotFound" class="column-footer">
              {{
                'FORM_INFO_CONTRATO.BROKERS_NOT_FOUND' | translate | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>

      <ng-container matColumnDef="codAgente">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'BROKERS_TABLE.HANDLE' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.codAgente }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nomAgente">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'BROKERS_TABLE.BROKER' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.nomAgente }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valComissao">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-valor">
              <app-icon-drag-drop />
              {{ 'BROKERS_TABLE.COMMISSION' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-valor">
            {{ element.valComissao }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="condicao">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'BROKERS_TABLE.CONDITION' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.condicao }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valTotalComissao">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-valor">
              <app-icon-drag-drop />
              {{ 'BROKERS_TABLE.AMOUNT' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-valor">
            {{ element.valTotalComissao }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-group-box>
