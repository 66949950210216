<div
  class="h-100 d-flex flex-lg-row flex-column justify-content-center align-items-center gap-4"
>
  <div class="order-lg-1 order-2 d-flex flex-column gap-4">
    <div class="title-container">
      <h1>{{ 'PAGE_ACCESS_DENIED.TITLE' | translate }}</h1>
    </div>
    <div class="subtitle-container">
      <span>{{ 'PAGE_ACCESS_DENIED.SUBTITLE' | translate }}</span>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <button mat-flat-button (click)="redirectToLogin()">
        {{ 'COMMON.BACK' | translate | uppercase }}
      </button>
    </div>
  </div>
  <div
    class="order-lg-2 order-1 h-100 d-flex justify-content-center align-items-center"
  >
    <div>
      <app-animation-access-denied />
    </div>
  </div>
</div>
