import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IFiliais } from 'src/app/interfaces/Filiais';
import { ControlAccessService } from '../../../auth/services/control-access.service';
import { RespostaPadrao } from 'src/app/interfaces/RespostaPadrao';
import { PermissoesFiliaisService } from '../PermissoesFiliais/permissoes-filiais.service';

@Injectable({
  providedIn: 'root',
})
export class FiliaisService {
  private filiaisSubject = new BehaviorSubject<IFiliais[]>(null);

  private filialSelecionadaSubject = new BehaviorSubject<IFiliais>(null);
  public filialSelecionada$ = this.filialSelecionadaSubject.asObservable();

  public tokenReq: string;

  constructor(
    private httpClient: HttpClient,
    private controlAccessService: ControlAccessService,
    private permissoesFiliaisService: PermissoesFiliaisService
  ) {
    this.tokenReq = localStorage.getItem('token-orchestrator');
  }

  fetchFiliais(cod_filiais: string): Observable<IFiliais[]> {
    
    const nomEmpresa = encodeURIComponent(localStorage.getItem('Empresa'));
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.tokenReq}`
    ).set('Empresa', nomEmpresa);

    const params = new HttpParams().set('cod_filiais', cod_filiais);

    return from(this.controlAccessService.getCompanyUrlBackend()).pipe(
      switchMap((base_url) => {
        const url = `${base_url}/api/filiais`;

        return this.httpClient
          .get<RespostaPadrao<IFiliais[]>>(url, { params, headers })
          .pipe(
            map((response) => {
              if (!response.success) return null;
              return response.data;
            }),
            tap((filiais) => this.filiaisSubject.next(filiais)),
            tap((filiais) => this.filialSelecionadaSubject.next(filiais[0])),
            catchError(() => throwError(() => null))
          );
      })
    );
  }

  getAllFiliais(): Observable<IFiliais[]> {
    const filiais = this.filiaisSubject.value;
    if (filiais && filiais.length > 0) return of(filiais);

    return this.permissoesFiliaisService
      .getPermissoes()
      .pipe(
        switchMap((permissoes) =>
          this.fetchFiliais(
            this.permissoesFiliaisService.getPermissoesSeparadoVirgula(
              permissoes
            )
          )
        )
      );
  }

  getFilialSelecionada(): Observable<IFiliais> {
    return this.filialSelecionadaSubject.asObservable();
  }

  setFilialSelecionada(filial: IFiliais): void {
    this.filialSelecionadaSubject.next(filial);
  }
}
