import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratoExpModalFaturamentoComponent } from './contrato-exp-modal-faturamento.component';
import { ContratoExpModalFaturamentoCondicoesComponent } from './components/contrato-exp-modal-faturamento-condicoes/contrato-exp-modal-faturamento-condicoes.component';
import { ContratoExpModalFaturamentoCanalBancComponent } from './components/contrato-exp-modal-faturamento-canal-banc/contrato-exp-modal-faturamento-canal-banc.component';
import { ContratoExpModalFaturamentoRecebimentosComponent } from './components/contrato-exp-modal-faturamento-recebimentos/contrato-exp-modal-faturamento-recebimentos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContratoExpModalCanalBancBeneficiarioComponent } from './components/contrato-exp-modal-faturamento-canal-banc/contrato-exp-modal-canal-banc-beneficiario/contrato-exp-modal-canal-banc-beneficiario.component';
import { ContratoExpModalCanalBancIntermediarioComponent } from './components/contrato-exp-modal-faturamento-canal-banc/contrato-exp-modal-canal-banc-intermediario/contrato-exp-modal-canal-banc-intermediario.component';

@NgModule({
  declarations: [
    ContratoExpModalFaturamentoComponent,
    ContratoExpModalFaturamentoCondicoesComponent,
    ContratoExpModalFaturamentoCanalBancComponent,
    ContratoExpModalFaturamentoRecebimentosComponent,
    ContratoExpModalCanalBancBeneficiarioComponent,
    ContratoExpModalCanalBancIntermediarioComponent,
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    CommonModule,
    TranslateModule,
    MatTableModule,
    DragDropModule,
    SharedModule,
  ],
  exports: [TranslateModule, ContratoExpModalFaturamentoComponent],
})
export class ContratoExpModalFaturamentoModule {}
