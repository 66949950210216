import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltipText',
})
export class TranslateTooltipPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case 'A EMBARCAR':
        return 'FLAGS.TO_SHIP_TOOLTIP';
      case 'EMBARCADO':
        return 'FLAGS.SHIPPED';
      case 'EM TRÂNSITO':
        return 'FLAGS.IN_TRANSIT';
      case 'ALFANDEGADO':
        return 'FLAGS.BONDED';
      default:
        return status;
    }
  }
}
