import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { IContrato } from 'src/app/interfaces/ContratoExp';

@Component({
  selector: 'app-contrato-exp-modal-faturamento',
  templateUrl: './contrato-exp-modal-faturamento.component.html',
  styleUrls: ['./contrato-exp-modal-faturamento.component.css'],
})

export class ContratoExpModalFaturamentoComponent {
  @Input() contrato: IContrato = {} as IContrato;
  faturamentoToggle = environment.faturamentoToggle;
}
