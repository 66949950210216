<div class="main-container">
  <button (click)="toggleBotoes()" class="btn btn-inicial">
    <i class="fas fa-gear icon-gear"></i>
  </button>
  <div
    class="actions"
    [class.active]="botoesVisiveis"
    *ngIf="botoesVisiveis || botoesAnimacao"
  >
    <button class="btn icon-logout" (click)="logout()">
      <i class="fa-solid fa-right-from-bracket"></i>
    </button>
    <button class="btn">
      <app-user-menu [isMobile]="true" />
    </button>
    <button class="btn"><app-select-language [isMobile]="true" /></button>
    <button class="btn"><app-button-toggle-theme /></button>
  </div>
</div>
