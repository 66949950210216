import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import { ContratoExpMaterialModule } from '../shared/material/contrato-exp-material.module';
import { SharedModule } from '../shared/shared.module';
import { LayoutMaterialModule } from '../shared/material/layout-material.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { MainLayoutComponent } from '../shared/layouts/main-layout/main-layout.component';
import { SelectLanguageComponent } from '../shared/layouts/main-layout/components/select-language/select-language.component';

import { ErrorComponent } from './error/error.component';
import { SimpleLayoutModule } from '../shared/layouts/simple-layout/simple-layout.module';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { UserMenuComponent } from './components/layout/components/user-menu/user-menu.component';
import { SideNavComponent } from './components/layout/components/side-nav/side-nav.component';
import { SpeedDialComponent } from '../shared/layouts/main-layout/components/speed-dial/speed-dial.component';
import { ButtonToggleThemeComponent } from '../shared/layouts/main-layout/components/button-toggle-theme/button-toggle-theme.component';
import { ModalSelectLanguageComponent } from '../shared/layouts/main-layout/components/modal-select-language/modal-select-language.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    SelectLanguageComponent,
    ButtonToggleThemeComponent,
    SpeedDialComponent,
    ModalSelectLanguageComponent,
    ErrorComponent,
    AccessDeniedComponent,
    UserMenuComponent,
    SideNavComponent,
  ],
  imports: [
    CommonModule,
    ContratoExpMaterialModule,
    PagesRoutingModule,
    SharedModule,
    LayoutMaterialModule,
    ToastrModule.forRoot(),
    TranslateModule,
    SimpleLayoutModule,
  ],
  exports: [TranslateModule],
})
export class PagesModule {}
