import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { IListContratos } from 'src/app/interfaces/ListContratos';

export type SelectImportadores = {
  value: string;
  viewValue: string;
};

@Component({
  selector: 'contratos-filtro-importadores',
  templateUrl: './filtro-importadores.component.html',
  styleUrls: ['./filtro-importadores.component.css'],
})
export class FiltroImportadoresComponent {
  @Input() contratos: IListContratos[] = [];
  @Input() limparSelecoes: boolean = false;
  @Input() fecharSelecoes: boolean = false;
  @Output() importadoresChange = new EventEmitter<string[]>();
  @ViewChild('select') select: MatSelect;

  public importadoresForm = new FormControl();
  public todosSelecionados: boolean = false;
  public options = [];

  public ngOnChanges(changes: SimpleChanges) {
    if (this.limparSelecoes) {
      this.deselecionarTodos();
      this.todosSelecionados = false;
    }
    if (this.fecharSelecoes) this.select.close();

    const prop = changes['contratos'];
    if (!prop) return;

    if (prop.firstChange) return;
    this.carregarImportadores();
  }

  public ngOnInit() {
    this.carregarImportadores();
  }

  private carregarImportadores() {
    const importadores = this.contratos
      .map((item) => item.Importador)
      .filter((item, index, self) => self.indexOf(item) === index);
    this.options = importadores.map((item) => ({
      value: item,
      viewValue: item,
    }));
  }

  public toggleAllSelection() {
    this.todosSelecionados ? this.deselecionarTodos() : this.selecionarTodos();
    this.emitEventSelect();
  }

  public toggleItem(todos: boolean) {
    if (todos) {
      this.toggleAllSelection();
      return;
    }
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.todosSelecionados = newStatus;
    if (this.itemAllSelecionado()) this.toggleAllSelection();
    if (!this.todosSelecionados) this.select.options.first.deselect();
    this.emitEventSelect();
  }

  private itemAllSelecionado(): boolean {
    return this.select.options
      .toArray()
      .slice(1)
      .every((item: MatOption) => item.selected);
  }

  public selecionarTodos() {
    this.select.options.forEach((item: MatOption) => item.select());
    this.todosSelecionados = true;
  }

  public deselecionarTodos() {
    this.select.options.forEach((item: MatOption) => item.deselect());
    this.todosSelecionados = false;
  }

  private emitEventSelect() {
    const importadores = this.importadoresForm.value
      .filter((item: SelectImportadores) => item.value !== '')
      .map((item: SelectImportadores) => item.value);
    this.importadoresChange.emit(importadores);
  }
}
