import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ContratoExpModalContratoDoisComponent } from './contrato-exp-modal-contrato-dois.component';
import { ContratoExpModalContratoDoisValorComponent } from './components/contrato-exp-modal-contrato-dois-valor/contrato-exp-modal-contrato-dois-valor.component';
import { ContratoExpModalContratoDoisFixacaoComponent } from './components/contrato-exp-modal-contrato-dois-fixacao/contrato-exp-modal-contrato-dois-fixacao.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContratoExpModalContratoDoisMarcacaoComponent } from './components/contrato-exp-modal-contrato-dois-marcacao/contrato-exp-modal-contrato-dois-marcacao.component';
import { ContratoExpModalContratoDoisNotasComponent } from './components/contrato-exp-modal-contrato-dois-notas/contrato-exp-modal-contrato-dois-notas.component';
import { ContratoExpModalContratoDoisOicsComponent } from './components/contrato-exp-modal-contrato-dois-oics/contrato-exp-modal-contrato-dois-oics.component';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ContratoExpModalContratoDoisComponent,
    ContratoExpModalContratoDoisValorComponent,
    ContratoExpModalContratoDoisFixacaoComponent,
    ContratoExpModalContratoDoisMarcacaoComponent,
    ContratoExpModalContratoDoisNotasComponent,
    ContratoExpModalContratoDoisOicsComponent,
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTooltipModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    DragDropModule
  ],
  exports: [TranslateModule, ContratoExpModalContratoDoisComponent],
})
export class ContratoExpModalContratoDoisModule {}
