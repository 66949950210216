<app-group-box [title]="'COMMON.CONDITIONS' | translate">
  <div class="row g-3">
    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.OPERATION' | translate
      }}</mat-label>
      <input
      disabled=true
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.OPERATION' | translate
          )
        }}"
        class="input-form-dgs"
        type="text"
        data-testid="nomOperacao"
        readonly
        [ngModel]="contrato.nomOperacao"
      />
    </div>

    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.RECEIPT_FORECAST' | translate
      }}</mat-label>
      <input
        placeholder="dd/mm/yyyy"
        disabled=true
        data-testid="cond_datPrevReceb"
        class="input-form-dgs"
        type="text"
        readonly
        [ngModel]="contrato.cond_datPrevReceb"
      />
    </div>

    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.ARBITRATION' | translate
      }}</mat-label>
      <input
      disabled=true
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.ARBITRATION' | translate
          )
        }}"
        class="input-form-dgs"
        data-testid="cond_arbitragem"
        type="text"
        readonly
        [ngModel]="contrato.cond_arbitragem"
      />
    </div>

    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.PAYMENT_CONDITION' | translate
      }}</mat-label>
      <input
      disabled=true
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.PAYMENT_CONDITION' | translate
          )
        }}"
        class="input-form-dgs"
        data-testid="condPgtoReceb"
        type="text"
        readonly
        [ngModel]="contrato.condPgtoReceb"
      />
    </div>
  </div>
</app-group-box>
