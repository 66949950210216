<app-loading [isLoading]="loading"></app-loading>
<div class="modal-overlay p-4">
  <div class="modal-container">
    <form>
      <div class="modal-header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center">
            <h1 class="m-3 title">
              {{ 'CONTRACT_EXPORTATION_MODAL.TITLE' | translate }}
            </h1>
          </div>
          <div class="d-flex align-items-center">
            <i
              (click)="closeModal()"
              class="fas fa-close text-color header-icon-close"
            ></i>
          </div>
        </div>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="contratoum-tab"
              data-bs-toggle="tab"
              data-bs-target="#contratoum"
              type="button"
              role="tab"
              aria-controls="contratoum"
              aria-selected="true"
            >
              {{ 'CONTRACT_EXPORTATION_MODAL.TAB_CONTRACT_I' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contratodois-tab"
              data-bs-toggle="tab"
              data-bs-target="#contratodois"
              type="button"
              role="tab"
              aria-controls="contratodois"
              aria-selected="false"
            >
              {{ 'CONTRACT_EXPORTATION_MODAL.TAB_CONTRACT_II' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="instembarque-tab"
              data-bs-toggle="tab"
              data-bs-target="#instembarque"
              type="button"
              role="tab"
              aria-controls="instembarque"
              aria-selected="false"
            >
              {{
                'CONTRACT_EXPORTATION_MODAL.TAB_BOARDING_INSTRUCTION_I'
                  | translate
              }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="reservacert-tab"
              data-bs-toggle="tab"
              data-bs-target="#reservacert"
              type="button"
              role="tab"
              aria-controls="reservacert"
              aria-selected="false"
            >
              {{
                'CONTRACT_EXPORTATION_MODAL.TAB_RESERVATION_CERTIFICATE'
                  | translate
              }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="transporte-tab"
              data-bs-toggle="tab"
              data-bs-target="#transporte"
              type="button"
              role="tab"
              aria-controls="transporte"
              aria-selected="false"
            >
              {{ 'CONTRACT_EXPORTATION_MODAL.TAB_TRANSPORT' | translate }}
            </button>
          </li>

          <li class="nav-item" role="presentation" *ngIf="faturamentoToggle">
            <button
              class="nav-link"
              id="faturamento-tab"
              data-bs-toggle="tab"
              data-bs-target="#faturamento"
              type="button"
              role="tab"
              aria-controls="faturamento"
              aria-selected="false"
            >
              {{ 'CONTRACT_EXPORTATION_MODAL.TAB_INVOICING' | translate }}
            </button>
          </li>
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="contratoum"
            role="tabpanel"
            aria-labelledby="contratoum-tab"
          >
            <app-contrato-exp-modal-contrato-um [contrato]="contrato" />
          </div>

          <div
            class="tab-pane fade"
            id="contratodois"
            role="tabpanel"
            aria-labelledby="contratodois-tab"
          >
            <app-contrato-exp-modal-contrato-dois [contrato]="contrato" />
          </div>
          <div
            class="tab-pane fade"
            id="instembarque"
            role="tabpanel"
            aria-labelledby="instembarque-tab"
          >
            <app-contrato-exp-modal-instrucao-embarque [contrato]="contrato" />
          </div>
          <div
            class="tab-pane fade"
            id="reservacert"
            role="tabpanel"
            aria-labelledby="reservacert-tab"
          >
            <app-contrato-exp-modal-reserva-certificado [contrato]="contrato" />
          </div>
          <div
            class="tab-pane fade"
            id="transporte"
            role="tabpanel"
            aria-labelledby="transporte-tab"
          >
            <app-contrato-exp-modal-transporte [contrato]="contrato" />
          </div>

          <div
            class="tab-pane fade"
            id="faturamento"
            role="tabpanel"
            aria-labelledby="faturamento-tab"
            *ngIf="faturamentoToggle"
          >
            <app-contrato-exp-modal-faturamento [contrato]="contrato"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
