<app-group-box [title]="'FORM_INFO_INVOICING.BANKING_CHANNEL' | translate">
  <div class="row g-4">
    <div class="col-12 col-md-12 col-lg">
      <div class="row g-3">
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.BENEFICIARY_BANK' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.BENEFICIARY_BANK' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.benefBank"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.SWIFT_CODE' | translate
          }}</mat-label>

          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.SWIFT_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.swiftCodeBenef"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.ABA_CODE' | translate
          }}</mat-label>

          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.ABA_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.abaCodeBenef"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.IBAN_CODE' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.IBAN_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.ibanCodeBenef"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.AGENCY' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.AGENCY' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.nomAgenciaBenef"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.CHECKING_ACCOUNT' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.CHECKING_ACCOUNT' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.contaCorrenteBenef"
          />
        </div>
      </div>
    </div>
    <div class="width-vr d-lg-block d-none">
      <app-divider orientation="V" />
    </div>
    <div class="d-lg-none">
      <app-divider orientation="H" />
    </div>
    <div class="col-12 col-md-12 col-lg">
      <div class="row g-3">
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.INTERMEDIARY_BANK' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.INTERMEDIARY_BANK' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.intermBank"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="lbl">{{
            'COMMON.SWIFT_CODE' | translate
          }}</mat-label>

          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.SWIFT_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.swiftCodeInterm"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="lbl">{{ 'COMMON.ABA_CODE' | translate }}</mat-label>

          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.ABA_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.abaCodeInterm"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.IBAN_CODE' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.IBAN_CODE' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.ibanCodeInterm"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.AGENCY' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.AGENCY' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.nomAgenciaInterm"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.CHECKING_ACCOUNT' | translate
          }}</mat-label>
          <input
            disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.CHECKING_ACCOUNT' | translate
              )
            }}"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.account"
          />
        </div>
      </div>
    </div>
  </div>
</app-group-box>
