import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CallBackConstants } from '../../constants/callback.constants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CallBackRedirectsService {
  private router = inject(Router);
  private authService = inject(AuthService);

  successRedirect() {
    this.router.navigate([CallBackConstants.CALL_BACK_SUCCESS]);
  }

  errorRedirect() {
    this.router.navigate([CallBackConstants.CALL_BACK_ERROR_PAGE]);
  }

  accessDeniedRedirect() {
    this.router.navigate([CallBackConstants.CALL_BACK_ACCESS_DENIED]);
  }

  refreshPermissionsRedirect() {
    this.authService.removeAuthToken();
    this.router.navigate([CallBackConstants.CALL_BACK_REDIRECT_ROOT]);
  }

  loginRedirect() {
    this.authService.removeAuthToken();
    window.location.href = environment.logoutCognitoAddress;
  }
}
