<app-group-box [title]="'FORM_INFO_CONTRATO_DOIS.NOTE' | translate">
  <div class="row">
    <div class="col-12">
      <textarea disabled=true placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.NOTE' | translate
          )
        }}" readonly class="input-form-dgs-textarea" type="text" data-testid="dscNotas" id="dscNotas" name="dscNotas"
        [ngModel]="contrato.dscNotas"></textarea>
    </div>
  </div>
</app-group-box>