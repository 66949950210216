import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  hide = true; // Mostrar ou esconder senha
  loading: boolean = false;
  usuario: string = '';
  senha: string = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  login() {
    const apiUrl = 'http://192.168.1.250:83/api/WebUsuario/login';

    // Certifique-se de que usuário e senha não estão vazios antes de fazer a requisição
    if (!this.usuario || !this.senha) {
      console.error('Usuário e senha são obrigatórios.');
      return;
    }

    this.loading = true; // Ativar o estado de carregamento antes da requisição

    const requestBody = {
      nom_usuario: this.usuario,
      nom_senha: this.senha,
    };

    this.http.post(apiUrl, requestBody, { responseType: 'text' }).subscribe(
      (token: string) => {
        // Lógica para lidar com o token retornado após o login bem-sucedido

        localStorage.setItem('token', token);
        localStorage.setItem('userName', this.usuario);

        // Use o Angular Router para redirecionar
        this.router.navigate(['/contatos-list']);
      },
      (error) => {
        // Lógica para lidar com erros na requisição
        console.error('Erro ao fazer login:', error);
      },
      () => {
        this.loading = false; // Desativar o estado de carregamento após a requisição
      }
    );
  }
}
