import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, finalize } from 'rxjs';
import { HTTPCode } from 'src/app/enums/HTTPCode';
import { StorageConstants } from 'src/app/shared/constants';
import { ErrorService } from './services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements AfterViewInit {
  private errorService = inject(ErrorService);
  private routerService = inject(Router);

  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);
  public loading = false;
  private errorSub$ = new Subscription();

  private routeDefault = '/contrato-exp';
  private previousRouteCallback = '/callback#';

  ngAfterViewInit() {
    const toasts = this.document.querySelectorAll(
      '.toastify.on.toastify-right.toastify-top'
    );
    toasts.forEach((toast) =>
      this.renderer.removeChild(toast.parentNode, toast)
    );
  }

  retryLastRequest() {
    this.loading = true;
    this.errorSub$ = this.errorService
      .retryLastRequestError()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: ({
          status,
          previousRoute,
        }: {
          status: HTTPCode;
          previousRoute: string;
        }) => {
          if (previousRoute.includes(this.previousRouteCallback)) {
            localStorage.removeItem(StorageConstants.LAST_REQUEST_ERROR);
            window.location.href = previousRoute;
          } else {
            if (status === HTTPCode.OK) {
              localStorage.removeItem(StorageConstants.LAST_REQUEST_ERROR);
             this.routerService.navigate([previousRoute]);
            }
          }
        },
        error: () => {
          localStorage.removeItem(StorageConstants.LAST_REQUEST_ERROR);
          this.routerService.navigate([this.routeDefault]);
        },
      });
  }

  ngOnDestroy() {
    this.errorSub$.unsubscribe();
  }
}
