import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment.dev';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-faturamento-canal-banc',
  templateUrl: './contrato-exp-modal-faturamento-canal-banc.component.html',
  styleUrls: ['./contrato-exp-modal-faturamento-canal-banc.component.css'],
})
export class ContratoExpModalFaturamentoCanalBancComponent {
  @Input() contrato: Partial<IContrato> = {};
  faturamentoToggle = environment.faturamentoToggle;
  constructor (
    public functionsDateService: FunctionsDateService
  ) {}
  
}
