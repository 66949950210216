import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './main/app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Modules
import { AuthModule } from './auth/auth.module';
import { ContratoExpModule } from './pages/contrato-exp/contrato-exp.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PagesModule } from './pages/pages.module';
import { NgxTranslateService } from './shared/services/translate/ngxTranslate.service';
import { Language } from './enums/Language';
import { HttpInterceptor } from './interceptors/http.interceptor';
import { LottieModule } from 'ngx-lottie';
import { ErrorService } from './pages/error/services/error.service';

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    AuthModule,
    ContratoExpModule,
    PagesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    ErrorService,
  ],
})
export class AppModule {
  constructor(private readonly translateService: NgxTranslateService) {
    const browserLang = this.translateService.getBrowserLanguage();
    const browerLangKey = this.translateService.getEnumKey(browserLang);
    this.translateService.setCurrentLanguage(Language[browerLangKey]);
  }
}
