<div class="row g-3">
  <div class="col-lg-2 col-md-12 col-12">
    <contratos-filtro-status-embarque
      [limparSelecoes]="limparTodosFiltros"
      [statusEmbarque]="statusEmbarque"
      [fecharSelecoes]="fecharTodosFiltros"
      (statusEmbarqueChange)="statusEmbarqueChange($event)"
    ></contratos-filtro-status-embarque>
  </div>

  <div class="col-lg-6 col-md-12 col-12">
    <contratos-filtro-data-embarque
      *ngIf="contratos.length > 0"
      [contratos]="contratos"
      [limparDatas]="limparTodosFiltros"
      (dataEmbarqueChange)="dataEmbarqueChange($event)"
    >
    </contratos-filtro-data-embarque>
  </div>
  <div class="col-lg-2 col-md-12 col-12">
    <contratos-filtro-importadores
      [contratos]="contratos"
      [limparSelecoes]="limparTodosFiltros"
      [fecharSelecoes]="fecharTodosFiltros"
      (importadoresChange)="importadoresChange($event)"
      *ngIf="contratos.length > 0"
    >
    </contratos-filtro-importadores>
  </div>
  
  <div class="col-lg-2 col-md-12 col-12 center-row-start align-items-end gap-3">
    <!-- Não remover essa class btn-filtro-aux -->
    <button
      class="btn-filtro-aux button-contratos-filtros"
      mat-flat-button
      (click)="aplicarFiltros()"
    >
      {{ 'BTN.FILTER' | translate | uppercase }}
    </button>
    <!-- Não remover essa class btn-filtro-aux -->
    <button
      class="mdc-button--outlined btn-filtro-aux clean-filter button-contratos-filtros"
      mat-stroked-button
      (click)="limparFiltros()"
    >
      {{ 'COMMON.CLEAR' | translate | uppercase }}
    </button>
  </div>
</div>
