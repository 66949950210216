<div class="row g-4">
  <div class="row g-3">
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.INTERMEDIARY_BANK' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.INTERMEDIARY_BANK' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="intermBank" [ngModel]="contrato.intermBank" />
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.SWIFT_CODE' | translate
        }}</mat-label>

      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.SWIFT_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="swiftCodeInterm" [ngModel]="contrato.swiftCodeInterm" />
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="lbl">{{ 'COMMON.ABA_CODE' | translate }}</mat-label>

      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.ABA_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="abaCodeInterm" [ngModel]="contrato.abaCodeInterm" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.IBAN_CODE' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.IBAN_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="ibanCodeInterm" [ngModel]="contrato.ibanCodeInterm" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.AGENCY' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.AGENCY' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="nomAgenciaInterm" [ngModel]="contrato.nomAgenciaInterm" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.CHECKING_ACCOUNT' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.CHECKING_ACCOUNT' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="account" [ngModel]="contrato.account" />
    </div>
  </div>
</div>