import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DeleteBtnComponent } from './components/delete-btn/delete-btn.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GroupBoxComponent } from './components/group-box/group-box.component';
import { InputMonthYearComponent } from './components/input-month-year/input-month-year.component';
import { InputSearchContractByNomFormatComponent } from './components/input-search-contract-by-nom-format/input-search-contract-by-nom-format.component';
import { MatInputModule } from '@angular/material/input';
import { LocalizedNumberPipe } from './pipes/localizedNumber.pipe';
import { IconDragDropComponent } from './components/icon-drag-drop/icon-drag-drop.component';
import { TranslateMonthYearPipe } from './pipes/translateMonthYear.pipe';
import { MatButtonModule } from '@angular/material/button';
import { AnimationCoffeeErrorComponent } from '../pages/error/components/error-animation-component';
import { AnimationAccessDeniedComponent } from '../pages/access-denied/components/access-denied-animation-component';
import { DividerComponent } from './components/divider/divider.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { TranslateTooltipPipe } from './pipes/translate-tooltip.pipe';

@NgModule({
  declarations: [
    DeleteBtnComponent,
    LoadingComponent,
    FooterComponent,
    InputSearchContractByNomFormatComponent,
    GroupBoxComponent,
    InputMonthYearComponent,
    LocalizedNumberPipe,
    TranslateMonthYearPipe,
    IconDragDropComponent,
    DividerComponent,
    TranslateTooltipPipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTableModule,
    AnimationCoffeeErrorComponent,
    AnimationAccessDeniedComponent,
  ],
  exports: [
    DeleteBtnComponent,
    LoadingComponent,
    FooterComponent,
    InputSearchContractByNomFormatComponent,
    GroupBoxComponent,
    InputMonthYearComponent,
    LocalizedNumberPipe,
    TranslateMonthYearPipe,
    IconDragDropComponent,
    AnimationCoffeeErrorComponent,
    AnimationAccessDeniedComponent,
    DividerComponent,
    TranslateTooltipPipe,
  ],
})
export class SharedModule {}
