import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Language } from 'src/app/enums/Language';
import { NgxTranslateService } from 'src/app/shared/services/translate/ngxTranslate.service';
import { ModalSelectLanguageComponent } from '../modal-select-language/modal-select-language.component';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css'],
})
export class SelectLanguageComponent implements OnInit {
  @Input() isMobile: boolean = false;

  public translateService = inject(NgxTranslateService);
  private dialog = inject(MatDialog);

  public enumLanguage = Language;
  public languages: Language[];
  public currentLanguage$: Observable<Language>;

  ngOnInit(): void {
    this.currentLanguage$ = this.translateService.getCurrentLanguage();
    this.languages = this.translateService.getLanguages();
  }

  openModalMobile(): void {
    this.dialog.open(ModalSelectLanguageComponent, {
      width: '160px',
      height: '160px',
    });
  }
}
