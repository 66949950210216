<app-group-box [title]="'COMMON.CERTIFICATES' | translate">
  <div class="table-container p-0">
    <table
      mat-table
      [dataSource]="certificados"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDropService.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span data-testid="rowNotFound" class="column-footer">
              {{
                'FORM_INFO_RESERVATION_CERTIFICATE.CERTIFICATES_NOT_FOUND'
                  | translate
                  | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>

      <ng-container matColumnDef="nomCertificado">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <app-icon-drag-drop />
          {{ 'COMMON.CERTIFICATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.nomCertificado }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-group-box>
