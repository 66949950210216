import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenProviderService {
  private endpoint = `${environment.apiOrchestratorUrl}/TokenProvider/api/token?secret=rA%2B6L%2FfsKC9j0c4a3IPjMMW4%2FovM9VmvFQ%2BVTtG2fO8%3D`;
  constructor(private http: HttpClient) {}

  async setTokenOrchestrator(): Promise<string> {
    return this.http.get(this.endpoint, { responseType: 'text' }).toPromise();
  }
}
