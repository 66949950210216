/* eslint-disable prettier/prettier */
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as jose from 'jose';
import { IUsuarioInfo } from '../interfaces/UsuarioInfo';
import { IPermissao } from '../interfaces/Permissao';
import { Router } from '@angular/router';
import { CallBackConstants } from 'src/app/shared/constants/callback.constants';
import { IVinculos } from '../interfaces/Vinculos';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authTokenKey = 'token';
  private router = inject(Router);
  constructor() {}

  async isAuthenticated(): Promise<boolean> {
    const token = this.getAuthToken();
    if (token != null) {
      if (await this.decodeToken(token)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getAuthToken(): string | null {
    const token = localStorage.getItem('token');
    return token;
  }

  getOrchestratorToken(): string | null {
    const tokenOrchestrator = localStorage.getItem('token-orchestrator');
    return tokenOrchestrator;
  }

  setAuthToken(key: string, token: string): void {
    localStorage.setItem(key, token);
  }

  removeAuthToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('token-orchestrator');
    localStorage.removeItem('dgs@last-request-error');
    localStorage.removeItem('Vínculos');
    localStorage.removeItem('Empresa');
    localStorage.removeItem('Company');
    localStorage.removeItem('empresa');
  }

  isString(value: unknown): value is string {
    return typeof value === 'string';
  }

  getValidString(value: unknown, defaultValue: string = ''): string {
    if (this.isString(value)) {
      return value;
    }
    return defaultValue;
  }

  async generateAuthToken(userAtributtes: IUsuarioInfo): Promise<string> {
    const secret = jose.base64url.decode(environment.secret);
    const jwt = await new jose.EncryptJWT({ ...userAtributtes })
      .setProtectedHeader({ alg: 'dir', enc: 'A128CBC-HS256' })
      .setIssuedAt()
      .setExpirationTime('2h')
      .encrypt(secret);
    return jwt;
  }

  async decodeToken(token: string): Promise<IUsuarioInfo | never> {
    const secretToDecrypt = jose.base64url.decode(environment.secret);
    const { payload } = await jose.jwtDecrypt(token, secretToDecrypt);

    try {
      const userAtributtes: IUsuarioInfo = {
        empresa: this.getValidString(payload['empresa']),
        nome: this.getValidString(payload['nome']),
        tipo: this.getValidString(payload['tipo']),
        email: this.getValidString(payload['email']),
        telefone: this.getValidString(payload['telefone']),
        url_api: this.getValidString(payload['url_api']),
        permissoes: payload['permissoes'] as Array<IPermissao>,
        vinculos: payload['vinculos'] as Array<IVinculos>,
      };

      return userAtributtes;
    } catch (error) {
      localStorage.clear();
      this.removeAuthToken();
      this.router.navigate([CallBackConstants.CALL_BACK_REDIRECT_ROOT]);
      throw new Error('Erro decodificando token');
    }
  }

  async getNomCompany(): Promise<string> {
    const token = this.getAuthToken();
    const userAttributes = await this.decodeToken(token);

    return userAttributes.empresa;
  }

  async getTypeUser(): Promise<string> {
    const token = this.getAuthToken();
    const userAttributes = await this.decodeToken(token);

    return userAttributes.tipo;
  }

  async getVinculos(): Promise<Array<IVinculos>> {
    const token = this.getAuthToken();
    const userAttributes = await this.decodeToken(token);

    return userAttributes.vinculos;
  }

  async getArrayCodVinculos(): Promise<Array<number>> {
    const codVinculos: Array<number> = [];

    const vinculos: Array<IVinculos> = await this.getVinculos();

    if (vinculos) {
      vinculos.forEach((vinculos) => {
        codVinculos.push(vinculos.cod_vinculo);
      });
    }

    return codVinculos;
  }
}
