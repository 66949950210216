import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FunctionsTranslateService {
  public getStatusTranslation(status: string): string {
    switch (status) {
      case 'A EMBARCAR':
        return 'FLAGS.TO_SHIP';
      case 'EMBARCADO':
        return 'FLAGS.SHIPPED';
      case 'EM TRÂNSITO':
        return 'FLAGS.IN_TRANSIT';
      case 'ALFANDEGADO':
        return 'FLAGS.BONDED';
      default:
        return status;
    }
  }

  public retornarMesesValor() {
    return [
      {
        key: 0,
        value: 'MONTH_COMPLETE.JANUARY',
      },
      {
        key: 1,
        value: 'MONTH_COMPLETE.FEBRUARY',
      },
      {
        key: 2,
        value: 'MONTH_COMPLETE.MARCH',
      },
      {
        key: 3,
        value: 'MONTH_COMPLETE.APRIL',
      },
      {
        key: 4,
        value: 'MONTH_COMPLETE.MAY',
      },
      {
        key: 5,
        value: 'MONTH_COMPLETE.JUNE',
      },
      {
        key: 6,
        value: 'MONTH_COMPLETE.JULY',
      },
      {
        key: 7,
        value: 'MONTH_COMPLETE.AUGUST',
      },
      {
        key: 8,
        value: 'MONTH_COMPLETE.SEPTEMBER',
      },
      {
        key: 9,
        value: 'MONTH_COMPLETE.OCTOBER',
      },
      {
        key: 10,
        value: 'MONTH_COMPLETE.NOVEMBER',
      },
      {
        key: 11,
        value: 'MONTH_COMPLETE.DECEMBER',
      },
    ];
  }
}
