<div class="row">
  <mat-label class="d-block lbl lbl-status-emb m-1" data-testid="title">{{
    title
    }}</mat-label>
  <div class="d-flex gap-1">
    <div class="form-group right-inner-addon">
      <input disabled=true readonly type="text" class="input-form-dgs"
        [placeholder]="'FORM_INFO_CONTRATO_DOIS.MONTH' | translate" [value]="month ?? ''" data-testid="input-month" />
    </div>
    <div class="form-group right-inner-addon">
      <input disabled=true readonly type="text" class="input-form-dgs"
        [placeholder]="'FORM_INFO_CONTRATO_DOIS.YEAR' | translate" [value]="year ?? ''" data-testid="input-year" />
    </div>
  </div>
</div>