import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleLayoutComponent } from './simple-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SimpleLayoutComponent],
  imports: [CommonModule, RouterModule, SharedModule, MatButtonModule],
  exports: [SimpleLayoutComponent],
})
export class SimpleLayoutModule {}
