import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IListContratos } from 'src/app/interfaces/ListContratos';
import { FunctionsTranslateService } from 'src/app/shared/utils/functions.translate.service';

@Component({
  selector: 'contratos-filtro-data-embarque',
  templateUrl: './filtro-data-embarque.component.html',
  styleUrls: ['./filtro-data-embarque.component.css'],
})
export class FiltroDataEmbarqueComponent implements OnInit {
  @Input() contratos: IListContratos[];
  @Input() limparDatas: boolean = false;
  @Output() dataEmbarqueChange = new EventEmitter<{
    dataInicial: Date;
    dataFinal: Date;
  }>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly functionsTranslateService: FunctionsTranslateService
  ) {}

  public mesesDe = this.functionsTranslateService.retornarMesesValor();
  public mesesAte = this.mesesDe;
  public anosDe = [];
  public anosAte = [];
  public formulario: FormGroup;

  ngOnChanges(changes: SimpleChanges) {
    if (this.limparDatas) {
      this.formulario.reset();
      return;
    }

    const prop = changes['contratos'];
    if (!prop) return;

    const primeiroRender = prop.firstChange;
    const propModificada = prop.currentValue !== prop.previousValue;
    if (primeiroRender) {
      this.anosDe = this.retornarIntervaloAnos(this.contratos);
      this.anosAte = this.anosDe;
    } else if (propModificada) {
      this.formulario.reset();
      this.anosDe = this.retornarIntervaloAnos(this.contratos);
      this.anosAte = this.anosDe;
      this.onFormValueChange();
    }
  }

  ngOnInit(): void {
    this.inicializarFormulario();
    this.formulario.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
  }

  private inicializarFormulario() {
    this.formulario = this.formBuilder.group({
      mesInicial: [null],
      anoInicial: [null],
      mesFinal: [null],
      anoFinal: [null],
    });

    this.formulario.get('mesInicial').valueChanges.subscribe(() => {
      this.resetarDataFinais();
    });

    this.formulario.get('anoInicial').valueChanges.subscribe((value) => {
      this.anosAte = this.anosDe.filter((ano) => ano >= value);
      this.resetarDataFinais();
    });
  }

  retornarIntervaloAnos(data: IListContratos[]) {
    const years: number[] = [];
    let unique: number[] = [];

    for (const i of data) {
      years.push(i._DataEmbarque.getFullYear());
      unique = [...new Set(years.map((item) => item))];
    }

    return unique;
  }

  private resetarDataFinais() {
    this.formulario.get('mesFinal').setValue(null);
    this.formulario.get('anoFinal').setValue(null);
  }

  private validarMes(mes: number) {
    return mes !== undefined && mes !== null && mes >= 0 && mes <= 11;
  }

  private validarAno(ano: number) {
    return ano !== undefined && ano !== null;
  }

  public onFormValueChange() {
    const { mesInicial, anoInicial, mesFinal, anoFinal } =
      this.formulario.value;

    const dateMesInicial = this.validarMes(mesInicial) ? mesInicial : 0;
    const dateMesFinal = this.validarMes(mesFinal) ? mesFinal : 11;

    const dateAnoInicial = this.validarAno(anoInicial)
      ? anoInicial
      : this.anosDe[this.anosDe.length - 1];
    const dateAnoFinal = this.validarAno(anoFinal) ? anoFinal : this.anosDe[0];

    const ultimoDia = new Date(dateAnoInicial, dateMesFinal + 1, 0).getDate();
    this.dataEmbarqueChange.emit({
      dataInicial: new Date(dateAnoInicial, dateMesInicial, 1),
      dataFinal: new Date(dateAnoFinal, dateMesFinal, ultimoDia),
    });
  }
}
