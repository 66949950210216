import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { IContrato } from 'src/app/interfaces/ContratoExp';

@Component({
  selector: 'app-contrato-exp-modal-reserva-certificado',
  templateUrl: './contrato-exp-modal-reserva-certificado.component.html',
  styleUrls: ['./contrato-exp-modal-reserva-certificado.component.css'],
})
export class ContratoExpModalReservaCertificadoComponent {
  @Input() contrato: Partial<IContrato> = {};
  reservaCertificadoToggle = environment.reservaCertificadoToggle;
}
