import { Component, Input, inject } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-faturamento-recebimentos',
  templateUrl: './contrato-exp-modal-faturamento-recebimentos.component.html',
  styleUrls: ['./contrato-exp-modal-faturamento-recebimentos.component.css'],
})
export class ContratoExpModalFaturamentoRecebimentosComponent {
  @Input() contrato: Partial<IContrato> = {};
  public displayedColumns = [
    'data',
    'valor',
    'observacao',
    'taxa_conv',
    'conta',
  ];

  tableDragDropTable = inject(TableDragDropService);

  constructor(public functionsDateService: FunctionsDateService) {}
}
