<div class="dropdown dropdown-language" *ngIf="!isMobile">
  <button
    class="btn btn-lg"
    [class.dropdown-toggle]="!isMobile"
    type="button"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    *ngIf="currentLanguage$ | async as currentLang"
  >
    <span class="flag-icon" [ngClass]="'flag-icon-' + currentLang">
      <img
        *ngIf="currentLang === enumLanguage.INGLES"
        src="/assets/eng.png"
        height="20"
        alt="English"
      />
      <img
        *ngIf="currentLang === enumLanguage.ESPANHOL"
        src="/assets/spa.png"
        height="20"
        alt="Spanish"
      />
      <img
        *ngIf="currentLang === enumLanguage.PORTUGUES"
        src="/assets/pt-br.png"
        height="20"
        alt="Português"
      />
    </span>
  </button>
  <ul
    class="dropdown-menu dropdown-lang-menu"
    aria-labelledby="dropdownMenuButton"
  >
    <li class="list-languages w-25 item-list" *ngFor="let language of languages">
      <button
        class="dropdown-item dropdown-lang-item"
        href="#"
        (click)="translateService.setCurrentLanguage(language)"
      >
        <span class="flag-icon" [ngClass]="'flag-icon-' + language">
          <img
            *ngIf="language === enumLanguage.INGLES"
            src="/assets/eng.png"
            height="20"
            alt="English"
          />
          <img
            *ngIf="language === enumLanguage.ESPANHOL"
            src="/assets/spa.png"
            height="20"
            alt="Spanish"
          />
          <img
            *ngIf="language === enumLanguage.PORTUGUES"
            src="/assets/pt-br.png"
            height="20"
            alt="Português"
          />
        </span>
      </button>
    </li>
  </ul>
</div>

<button
  class="btn btn-lg"
  type="button"
  *ngIf="isMobile && currentLanguage$ | async as currentLang"
  (click)="openModalMobile()"
>
  <span class="flag-icon" [ngClass]="'flag-icon-' + currentLang">
    <img
      *ngIf="currentLang === enumLanguage.INGLES"
      src="/assets/eng.png"
      height="20"
      alt="English"
    />
    <img
      *ngIf="currentLang === enumLanguage.ESPANHOL"
      src="/assets/spa.png"
      height="20"
      alt="Spanish"
    />
    <img
      *ngIf="currentLang === enumLanguage.PORTUGUES"
      src="/assets/pt-br.png"
      height="20"
      alt="Português"
    />
  </span>
</button>
