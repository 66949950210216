<div class="lbl-status-emb-ellipsis">
  <mat-label class="lbl lbl-status-emb"
    >{{ 'COMMON.IMPORTER' | translate }}:</mat-label
  >
</div>
<mat-form-field>
  <mat-select
    #select
    [formControl]="importadoresForm"
    multiple
    placeholder="{{ 'COMMON.ALL' | translate }}"
  >
    <mat-option [value]="''" (click)="toggleItem(true)">
      {{ 'FLAGS.All' | translate }}
    </mat-option>

    <mat-option
      *ngFor="let option of options"
      [value]="option"
      (click)="toggleItem(false)"
    >
      {{ option.viewValue | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
