import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateMonthYear',
  pure: false,
})
export class TranslateMonthYearPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: string): string {
    if (!date) return ''; // Check if date is null or undefined

    const [month, year] = date.split('/');
    const translatedMonth = this.translateService.instant('MONTH.' + month);
    return `${translatedMonth}/${year}`;
  }
}
