import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.dev';
import {
  catchError,
  from,
  map,
  of,
  switchMap,
  throwError,
  Observable,
  BehaviorSubject,
  tap,
} from 'rxjs';
import { IPermissao } from 'src/app/auth/interfaces/Permissao';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ControlAccessService } from 'src/app/auth/services/control-access.service';

@Injectable({
  providedIn: 'root',
})
export class PermissoesFiliaisService {
  private permissoesSubject = new BehaviorSubject<IPermissao[]>(null);

  constructor(
    private readonly controlAccessService: ControlAccessService,
    private readonly authService: AuthService
  ) {}

  public getPermissoes(): Observable<IPermissao[]> {
    const permissoes = this.permissoesSubject.value;
    if (permissoes && permissoes.length > 0) return of(permissoes);
    return this.carregarPermissoes();
  }

  public carregarPermissoes() {
    const token = this.authService.getAuthToken();
    const userAttributesPromise = this.authService.decodeToken(token);
    const userAttributes$ = from(userAttributesPromise);

    return userAttributes$.pipe(
      switchMap((userAttributes) => {
        if (!userAttributes || !userAttributes.permissoes) return of(null);
        return this.controlAccessService
          .getUserPermissions(
            environment.local ? environment.usuarioLocal : userAttributes.nome
          )
          .pipe(
            map((response) =>
              response.data.length > 0 ? response.data : null
            ),
            tap((permissions) => this.permissoesSubject.next(permissions))
          );
      }),
      catchError(() => throwError(() => of(null)))
    );
  }

  public getPermissoesSeparadoVirgula(permissions: IPermissao[]): string {
    let avaiableFilials = '';
    permissions.forEach((permission) =>
      avaiableFilials === ''
        ? (avaiableFilials = permission.codFilial.toString())
        : (avaiableFilials += ',' + permission.codFilial.toString())
    );
    return avaiableFilials;
  }
}
