import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageConstants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private http = inject(HttpClient);

  private getHeadersLastRequestError(
    headers: { key: string; value: string }[]
  ) {
    return headers.reduce((acc, header) => {
      return acc.append(header.key, header.value);
    }, new HttpHeaders());
  }

  private getLastRequestError(): string {
    return localStorage.getItem(StorageConstants.LAST_REQUEST_ERROR);
  }

  retryLastRequestError(): Observable<any> {
    try {
      const lastRequestError = this.getLastRequestError();

      if (lastRequestError) {
        const request = JSON.parse(lastRequestError);
        const headers = this.getHeadersLastRequestError(request.headers);

        return this.http
          .request(request.method, request.url, {
            headers,
            observe: 'response',
          })
          .pipe(
            map((response) => ({
              status: response.status,
              previousRoute: request.previousRoute,
            })),
            catchError(({ status }) =>
              of({ status, previousRoute: request.previousRoute })
            )
          );
      }
      return throwError(() => 'No Storage');
    } catch (error) {
      return throwError(() => error.message);
    }
  }
}
