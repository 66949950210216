<app-loading [isLoading]="loading"></app-loading>

<script src="https://cdnjs.cloudflare.com/ajax/libs/Sortable/1.14.0/Sortable.min.js"></script>

<div class="content-wrapper p-0 vh-100">
  <div class="d-flex">
    <nav class="navbar navbar-expand-lg navbar-light w-100 mb-0">
      <div class="container-fluid d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-center h-100">
          <button
            *ngIf="sideNavToggle"
            (click)="toggleSidebar()"
            id="menuIcon"
            class="btn btn-toggle p-1"
          >
            <i class="fas fa-bars fa-2x"></i>
          </button>

          <img src="/assets/d_logobranca.png" class="logo-navbar" alt="logo" />

          <div class="dropdown dropdown-filial">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              onclick="btnclick()"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="/assets/icon_filial.svg" class="icon-filial" />
              <span *ngIf="selectedFilial" class="nom-filial">
                  {{
                    selectedFilial.codFilial + ' - ' + selectedFilial.nomFilial
                  }}
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-filial-menu p-3 rounded"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                *ngFor="let filial of filiais"
                class="dropdown-item dropdown-item-filial"
                (click)="filialChanged(filial)"
              >
                {{ filial.codFilial }} - {{ filial.nomFilial }}
              </a>
            </div>
          </div>
        </div>

        <div class="container-left center-row-end gap-3">
          <app-button-toggle-theme />
          <app-select-language />
          <app-user-menu></app-user-menu>
        </div>
      </div>
    </nav>
  </div>
  <div class="d-flex">
    <app-side-nav
      [darkMode]="darkMode"
      [expanded]="expanded"
      [sidebarVisible]="sidebarVisible"
      (sidebarVisibleChanged)="sidebarVisibleChanged($event)"
      *ngIf="sideNavToggle"
    />

    <div class="d-flex w-100 flex-column px-3">
      <main class="w-100 py-3">
        <div
          [ngClass]="
            NoBorderContainer ? 'card-content-no-border' : 'card-content p-4'
          "
          [style.max-width]="getCardContentMaxWidth()"
        >
          <router-outlet>
            <!---------------------------------->
            <!-- Renderiza conteúdo da página -->
            <!---------------------------------->
            <div class="app-sidenav-content">
              <ng-content></ng-content>
            </div>
          </router-outlet>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
    <div class="speed-dial">
      <app-speed-dial></app-speed-dial>
    </div>
  </div>
</div>
