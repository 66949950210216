import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private authUsuarioKey = 'userName';

  constructor() {}

  getUserLogged(): string | null {
    const userName = localStorage.getItem(this.authUsuarioKey);
    return userName;
  }

  setUserLogged(key: string, user: string): void {
    localStorage.setItem(key, user);
  }

  removeAuthUser(): void {
    localStorage.removeItem(this.authUsuarioKey);
  }
}
