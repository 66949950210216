import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-contrato-exp-modal-contrato-dois',
  templateUrl: './contrato-exp-modal-contrato-dois.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-dois.component.css'],
})
export class ContratoExpModalContratoDoisComponent {
  @Input() contrato: IContrato = {} as IContrato;
  ContratoDoisToggle = environment.contratoDoisToggle;
}
