import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor as NgHttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageConstants } from '../shared/constants';
import { HTTPCode } from '../enums/HTTPCode';

@Injectable()
export class HttpInterceptor implements NgHttpInterceptor {
  private router = inject(Router);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentRoute = this.router.url;

    if (currentRoute.includes('error-page')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          [
            HTTPCode.UNKNOWN,
            HTTPCode.BAD_REQUEST,
            HTTPCode.FORBIDDEN,
            HTTPCode.INTERNAL_SERVER_ERROR,
            HTTPCode.SERVICE_UNAVAILABLE,
          ].includes(error.status)
        ) {
          const requestDetails = {
            url: req.urlWithParams,
            method: req.method,
            headers: req.headers.keys().map((key) => ({
              key,
              value: req.headers.get(key),
            })),
            body: req.body,
            previousRoute: currentRoute,
          };

          localStorage.setItem(
            StorageConstants.LAST_REQUEST_ERROR,
            JSON.stringify(requestDetails)
          );

          this.router.navigate(['/error-page']);
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
