<app-group-box [title]="'COMMON.OICS' | translate">
  <div class="table-container p-0">
    <table
      mat-table
      [dataSource]="oics"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDrop.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span data-testid="rowNotFound" class="column-footer">
              {{
                'FORM_INFO_CONTRATO_DOIS.OICS_NOT_FOUND' | translate | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>

      <ng-container matColumnDef="num_oic">
        <th
          [matTooltip]="'COMMON.OIC_TOOLTIP' | translate"
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.OIC' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.num_oic }}</td>
      </ng-container>

      <ng-container matColumnDef="ano">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.YEAR' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.ano }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sacas">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-sacas">
              <app-icon-drag-drop />
              {{ 'COMMON.BAGS' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-sacas">
            {{ element.sacas | localizedNumber }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="kg">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-sacas">
              <app-icon-drag-drop />
              {{ 'COMMON.KG' | translate }}
            </div>
          </div>
        </th>
        <td class="text-left" mat-cell *matCellDef="let element">
          <div class="width-sacas">
            {{ element.kg | localizedNumber }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contramarca">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'FORM_INFO_CONTRATO_DOIS.AGAINST_BRAND' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.contramarca }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-group-box>
