<nav
  class="sidebar"
  [ngClass]="{ hidden: !sidebarVisible, expanded: expanded }"
  (mouseenter)="hoverSidebar(expanded, isMobile)"
  (mouseleave)="hoverSidebar(expanded, isMobile)"
>
  <!-- <div class="teste d-flex w-100 justify-content-center align-center mt-3">
    <img
      *ngIf="logoToggle"
      [src]="
        darkMode
          ? '../../../assets/logobranca-sidebar.png'
          : '../../../assets/logo.png'
      "
      class="logo mt-4 mb-5"
      alt=""
    />
  </div> -->

  <div class="w-100 mt-1">
    <ul class="w-100">
      <li
        class="w-100 p-2"
        [class.active-router-sidebar]="currentUrl === '/dashboards'"
      >
        <a
          [routerLink]="'/dashboards'"
          class="d-flex justify-content-start align-items-center"
        >
          <i class="fas fa-chart-bar fas-2x"></i>
          <span *ngIf="expanded || isMobile" class="nav-text">{{
            'SIDE_NAV_MENU.DASHBOARD' | translate
          }}</span>
        </a>
      </li>

      <li
        class="w-100 p-2 mt-2"
        [class.active-router-sidebar]="currentUrl === '/contrato-exp'"
      >
        <a
          class="d-flex justify-content-start align-items-center"
          [routerLink]="'/contrato-exp'"
        >
          <i class="fas fa-handshake fas-2x" style="text-align: center"></i>
          <span *ngIf="expanded || isMobile" class="nav-text">{{
            'SIDE_NAV_MENU.CONTRACT_LIST' | translate
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
