<form [formGroup]="formulario">
  <div class="row g-3">
    <div class="col-lg-3 col-md-6 col-6">
      <mat-label class="d-block lbl">{{
        'COMMON.FROM' | translate | uppercase
      }}</mat-label>
      <mat-form-field>
        <span matPrefix><mat-icon>date_range</mat-icon></span>
        <mat-select
          formControlName="mesInicial"
          placeholder="{{ 'COMMON.START_MONTH' | translate }}"
        >
          <mat-option *ngFor="let mesDe of mesesDe" [value]="mesDe.key">{{
            mesDe.value | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-lg-3 col-md-6 col-6">
      <mat-label class="d-block invisivel">.</mat-label>
      <mat-form-field>
        <span matPrefix><mat-icon>date_range</mat-icon></span>
        <mat-select
          formControlName="anoInicial"
          placeholder="{{ 'COMMON.START_YEAR' | translate }}"
        >
          <mat-option *ngFor="let anoDe of anosDe" [value]="anoDe">{{
            anoDe
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-lg-3 col-md-6 col-6">
      <mat-label class="d-block lbl">{{
        'COMMON.TO' | translate | uppercase
      }}</mat-label>
      <mat-form-field>
        <span matPrefix><mat-icon>date_range</mat-icon></span>
        <mat-select
          formControlName="mesFinal"
          placeholder="{{ 'COMMON.END_MONTH' | translate }}"
        >
          <mat-option *ngFor="let mesAte of mesesAte" [value]="mesAte.key">{{
            mesAte.value | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-6 col-6">
      <mat-label class="d-block invisivel">.</mat-label>
      <mat-form-field>
        <span matPrefix><mat-icon>date_range</mat-icon></span>
        <mat-select
          formControlName="anoFinal"
          placeholder="{{ 'COMMON.END_YEAR' | translate }}"
        >
          <mat-option *ngFor="let anoAte of anosAte" [value]="anoAte">{{
            anoAte
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
