<app-group-box [title]="'COMMON.PRIOR_NOTICE' | translate">
  <div class="row g-3">
    <div class="col-12 col-md-2 col-lg-2">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.NUMBER' | translate
      }}</mat-label>
      <input
      disabled=true
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.NUMBER' | translate
          )
        }}"
        data-testid="numPN"
        readonly
        class="input-form-dgs"
        type="text"
        [ngModel]="contrato.numPN"
      />
    </div>

    <div class="col-12 col-md-2 col-lg-2">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_RESERVATION_TRANSPORT.SEND_DATE' | translate
      }}</mat-label>
      <input
      placeholder="dd/mm/yyyy"
      disabled=true
        data-testid="datEnvioPN"
        readonly
        class="input-form-dgs"
        type="text"
        [ngModel]="contrato.datEnvioPN ?? ''"
      />
    </div>
  </div>
</app-group-box>
