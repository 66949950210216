<div class="dropdown dropdown-user-menu" *ngIf="!isMobile">
  <button
    class="btn btn-lg dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
  <i class="fa-solid fa-circle-user"></i>
    <span class="user-name">
      {{ userName }}
    </span>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <button class="btn dropdown-logout-bnt" (click)="logout()" title="Logout">
      <i
        class="fa-solid fa-right-from-bracket logout-btn"
        style="color: var(--light-icon); font-size: 15px"
      ></i>
      <span class="flag-icon">{{ 'USER_MENU.LOGOUT' | translate }}</span>
    </button>
  </ul>
</div>

<div
  *ngIf="isMobile"
  class="rounded-circle"
  matTooltipPosition="left"
  [matTooltip]="userName"
  #tooltip="matTooltip"
  (click)="toggleTooltip(tooltip)"
>
  <span class="letters"> {{ getInitialLetters(userName) }}</span>
</div>
