import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.css'],
})
export class SimpleLayoutComponent {
  constructor() {
    this.applyTheme();
  }
  applyTheme() {
    const darkMode = localStorage.getItem('darkMode');
    const parsedDarkMode = JSON.parse(darkMode);

    document.documentElement.classList.add(
      parsedDarkMode ? 'dark-mode' : 'light-mode'
    );
    document.documentElement.classList.remove(
      parsedDarkMode ? 'light-mode' : 'dark-mode'
    );
  }
}
