<app-group-box [title]="'COMMON.TRANSPORTATION' | translate">
  <div class="row g-3">
    <div class="col-12 col-md-12 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.SHIPPING_COMPANY' | translate
      }}</mat-label>
      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.SHIPPING_COMPANY' | translate
          )
        }}"
        data-testid="nomTransportadora"
        readonly
        class="input-form-dgs"
        type="text"
        [ngModel]="contrato.nomTransportadora"
      />
    </div>
    <div class="col-12 col-md-4 col-lg-2">
      <mat-label class="d-block lbl mb-1">{{
        'FORM_INFO_RESERVATION_TRANSPORT.QUANTITY_OUTPUT_KG' | translate
      }}</mat-label>

      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_RESERVATION_TRANSPORT.QUANTITY_OUTPUT_KG' | translate
          )
        }}"
        data-testid="qtdSaidaKg"
        readonly
        class="input-form-dgs text-end"
        type="text"
        [ngModel]="contrato.qtdSaidaKg"
      />
    </div>
    <div class="col-12 col-md-4 col-lg-2">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.RETURN' | translate
      }}</mat-label>
      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.RETURN' | translate
          )
        }}"
        data-testid="devolucao"
        readonly
        class="input-form-dgs text-end"
        type="text"
        [ngModel]="contrato.devolucao"
      />
    </div>
    <div class="col-12 col-md-4 col-lg-2">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.BALANCE' | translate
      }}</mat-label>
      <input
        disabled="true"
        placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'COMMON.BALANCE' | translate
          )
        }}"
        data-testid="saldo"
        readonly
        class="input-form-dgs text-end"
        type="text"
        [ngModel]="contrato.saldo"
      />
    </div>
  </div>
  <div class="table-container p-0 pt-3">
    <table
      mat-table
      [dataSource]="contrato.containers"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDropTable.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span data-testid="rowNotFound" class="column-footer">
              {{
                'FORM_INFO_RESERVATION_TRANSPORT.CONTAINERS_NOT_FOUND'
                  | translate
                  | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>
      <ng-container matColumnDef="nomContainer">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.CONTAINER' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="fw-bold">
          {{ element.nomContainer }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lacreArmador">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'FORM_INFO_RESERVATION_TRANSPORT.ARMOR_SEAL' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lacreArmador }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lacreExportador">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'FORM_INFO_RESERVATION_TRANSPORT.EXPORT_SEAL' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lacreExportador }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lacreReceitaFederal">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{
              'FORM_INFO_RESERVATION_TRANSPORT.FEDERAL_REVENUE_SEAL' | translate
            }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lacreReceitaFederal }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tara">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-tara">
              <app-icon-drag-drop />
              {{ 'COMMON.TARE' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-tara">
            {{ element.tara }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ctrc">
        <th
          [matTooltip]="'COMMON.CTRC_TOOLTIP' | translate"
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.CTRC' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.ctrc }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pesoKg">
        <th
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <div class="width-peso">
              <app-icon-drag-drop />
              {{ 'COMMON.WEIGHT_KG' | translate }}
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-peso">
            {{ element.pesoKg | localizedNumber }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="numOic">
        <th
          [matTooltip]="'COMMON.OIC_TOOLTIP' | translate"
          class="column-title"
          mat-header-cell
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="center-row-start">
            <app-icon-drag-drop />
            {{ 'COMMON.OIC' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.numOic }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-group-box>
