import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-speed-dial',
  templateUrl: './speed-dial.component.html',
  styleUrls: ['./speed-dial.component.css'],
})
export class SpeedDialComponent {
  private router = inject(Router);
  private authService = inject(AuthService);

  botoesVisiveis: boolean = false;
  botoesAnimacao: boolean = false;

  public toggleBotoes() {
    if (this.botoesVisiveis) {
      this.botoesVisiveis = false;
      setTimeout(() => (this.botoesAnimacao = false), 100);
    } else {
      this.botoesAnimacao = true;
      setTimeout(() => (this.botoesVisiveis = true), 0);
    }
  }

  public logout() {
    // localStorage.removeItem('token');
    // this.router.navigate(['/login']);
    this.authService.removeAuthToken();
    window.location.href = environment.logoutCognitoAddress;
  }
}
