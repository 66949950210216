import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContratoExpModalTransporteComponent } from './contrato-exp-modal-transporte.component';
import { ContratoExpModalTransporteTranspComponent } from './components/contrato-exp-modal-transporte-transp/contrato-exp-modal-transporte-transp.component';
import { ContratoExpModalTransporteBlComponent } from './components/contrato-exp-modal-transporte-bl/contrato-exp-modal-transporte-bl.component';
import { ContratoExpModalTransportePnComponent } from './components/contrato-exp-modal-transporte-pn/contrato-exp-modal-transporte-pn.component';

@NgModule({
  declarations: [
    ContratoExpModalTransporteComponent,
    ContratoExpModalTransporteTranspComponent,
    ContratoExpModalTransporteBlComponent,
    ContratoExpModalTransportePnComponent,
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    CommonModule,
    TranslateModule,
    MatTableModule,
    DragDropModule,
    SharedModule,
  ],
  exports: [TranslateModule, ContratoExpModalTransporteComponent],
})
export class ContratoExpModalTransporteModule {}
