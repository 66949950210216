import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratoExpModalContratoUmAgentComponent } from './components/contrato-exp-modal-contrato-um-agent/contrato-exp-modal-contrato-um-agent.component';
import { ContratoExpModalContratoUmComponent } from './contrato-exp-modal-contrato-um.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContratoExpModalContratoUmInfoComponent } from './components/contrato-exp-modal-contrato-um-info/contrato-exp-modal-contrato-um-info.component';



@NgModule({
  declarations: [
    ContratoExpModalContratoUmComponent,
    ContratoExpModalContratoUmAgentComponent,
    ContratoExpModalContratoUmInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatTableModule,
    MatFormFieldModule,
    DragDropModule,
    SharedModule
  ],
  exports: [
    TranslateModule,
    ContratoExpModalContratoUmComponent
  ],
})
export class ContratoExpModalContratoUmModule { }
