export enum Month {
  Jan = 'MONTH.Jan',
  Feb = 'MONTH.Feb',
  Mar = 'MONTH.Mar',
  Apr = 'MONTH.Apr',
  May = 'MONTH.May',
  Jun = 'MONTH.June',
  Jul = 'MONTH.July',
  Aug = 'MONTH.Aug',
  Sept = 'MONTH.Sept',
  Oct = 'MONTH.Oct',
  Nov = 'MONTH.Nov',
  Dec = 'MONTH.Dec',
}
