import { Pipe, type PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxTranslateService } from '../../shared/services/translate/ngxTranslate.service';
import { Language } from 'src/app/enums/Language';

@Pipe({
  name: 'localizedNumber',
  pure: false,
})
export class LocalizedNumberPipe implements PipeTransform {
  private currentLanguage = Language.PORTUGUES;
  private langSubscription: Subscription;

  constructor(private readonly translateService: NgxTranslateService) {
    this.langSubscription = this.translateService
      .getCurrentLanguage()
      .subscribe((language) => (this.currentLanguage = language));
  }
  transform(value: string | number): string {
    if (value === 0) return '0';
    if (!value) return '';
    let finalValue = 0;

    switch (typeof value) {
      case 'number': {
        finalValue = value;
        break;
      }
      case 'string': {
        const formatedValue = value.replace('.', '').replace(',', '.');
        const intFormated = parseFloat(formatedValue);
        finalValue = intFormated;
        break;
      }
      default: {
        finalValue = 0;
        break;
      }
    }

    const numberFormat = new Intl.NumberFormat(this.currentLanguage, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
    return numberFormat.format(finalValue);
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
