import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { IContrato } from 'src/app/interfaces/ContratoExp';

@Component({
  selector: 'app-contrato-exp-modal-transporte',
  templateUrl: './contrato-exp-modal-transporte.component.html',
  styleUrls: ['./contrato-exp-modal-transporte.component.css'],
})
export class ContratoExpModalTransporteComponent {
  transporteToggle = environment.transporteToggle;

  @Input() contrato: IContrato = {} as IContrato;
}
