import { Injectable } from '@angular/core';
import Toastify from 'toastify-js';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  showToast(message: string, type: string): void {
    const backgroundColor =
      type === 'success' ? 'rgba(76, 175, 80, 0.9)' : 'rgba(244, 67, 54, 0.9)';

    this.showToastWithOptions({
      text: message,
      duration: 2000,
      gravity: 'top',
      position: 'right',
      backgroundColor: backgroundColor,
      style: {
        marginTop: '80px',
        opacity: '0.9',
      },
    });
  }

  showToastWithOptions(options: Toastify.Options): void {
    Toastify(options).showToast();
  }
}
