import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserService } from 'src/app/auth/services/user.service';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
})
export class UserMenuComponent {
  @Input() isMobile: boolean = false;
  @Input() userName: string = this.functionsDateService.capitalizeFirstLetter(
    this.userService.getUserLogged()
  );

  constructor(
    private readonly userService: UserService,
    private router: Router,
    public functionsDateService: FunctionsDateService
  ) {}

  getInitialLetters(name: string) {
    if (!name) return '';
    const nomes = name.split(' ');
    return nomes[0][0].toUpperCase();
  }

  logout(): void {
    //this.authService.removeAuthToken();
    this.router.navigate(['/logout']);
  }

  toggleTooltip(tooltip: MatTooltip) {
    if (tooltip._isTooltipVisible()) {
      tooltip.hide();
    } else {
      tooltip.show();
    }
  }
}
