import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { catchError, from, Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';
import { IPermissao } from '../interfaces/Permissao';

@Injectable({
  providedIn: 'root',
})
export class ControlAccessService {
  private endpointOrchestrator = `${environment.apiOrchestratorUrl}/api/Licencas/`;

  public tokenReq: string;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getUserPermissions(nom_user: string): Observable<{
    success: boolean;
    message: string;
    data: IPermissao[];
  }> {
    const tokenOrchestrator = localStorage.getItem('token-orchestrator');
    let params: HttpParams;
    params = new HttpParams().set('nom_usuario', nom_user);

    const nomEmpresa = encodeURIComponent(localStorage.getItem('Empresa'));
    
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${tokenOrchestrator}`
    ).set('Empresa', nomEmpresa);

    return from(this.getCompanyUrlBackend()).pipe(
      switchMap((base_url) => {
        const url = `${base_url}/api/permissoes`;

        return this.http.get<{
          success: boolean;
          message: string;
          data: any;
        }>(url, {
          params,
          headers,
        });
      }),
      // catchError((error) => {
      //   console.error('Erro ao recuperar permissões:', error);
      //   return of(null);
      // })
    );
  }

  getLicenca(
    nomRazao: string,
    tokenReq: string
  ): Observable<HttpResponse<any>> {
    const encodedNomRazao = encodeURIComponent(nomRazao);
    const url = `${this.endpointOrchestrator}${encodedNomRazao}`;
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${tokenReq}`)
      .set('Accept', 'text/plain');

    // Note the return type is now Observable<HttpResponse<any>>
    return this.http.get<any>(url, { headers, observe: 'response' });
  }

  async getCompanyUrlBackend(): Promise<string> {
    const urlApi = environment.apiUrl;
    return urlApi;
  }
}
