import { Component, Input, inject } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-transporte-transp',
  templateUrl: './contrato-exp-modal-transporte-transp.component.html',
  styleUrls: ['./contrato-exp-modal-transporte-transp.component.css'],
})
export class ContratoExpModalTransporteTranspComponent {
  @Input() contrato: IContrato = {} as IContrato;

  constructor (
    public functionsDateService: FunctionsDateService
  ) {}

  public displayedColumns = [
    'nomContainer',
    'lacreArmador',
    'lacreExportador',
    'lacreReceitaFederal',
    'tara',
    'ctrc',
    'pesoKg',
    'numOic',
  ];
  tableDragDropTable = inject(TableDragDropService);
}
