// app-contratos-exp-edit-modal
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { ContratosService } from '../services/contrato-exp.service';
import { finalize } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-contratos-exp-edit-modal',
  templateUrl: './contrato-exp-modal.component.html',
  styleUrls: ['./contrato-exp-modal.component.css'],
})
export class ContratosEditModalComponent {
  contrato: IContrato;
  loading: boolean = false;
  handleContrato: number;

  constructor(
    public dialogRef: MatDialogRef<ContratosEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public contratosService: ContratosService
  ) {
    this.contrato = { ...data.contrato };
    this.handleContrato = this.data;
    this.getDadosContrato(this.handleContrato);
  }

  closeModal() {
    this.dialogRef.close();
  }

  faturamentoToggle = environment.faturamentoToggle;
  getDadosContrato(handleContrato: number) {
    this.loading = true;
    this.contratosService
      .fetchDadosContrato(handleContrato)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data: IContrato[]) => {
        if (data.length > 0) this.contrato = data[0];
      });
  }
}
