import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ContratoExpViewComponent } from './contrato-exp-view/contrato-exp-view.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ContratoExpViewComponent,
      }
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContratoExpRoutingModule {}
