import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Guards
import { NotFoundGuard } from './auth/guard/not-found.guard';

//Components
import { CallbackComponent } from './auth/components/callback/callback.component';
import { LogoutComponent } from './auth/components/logout/logout.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { ErrorComponent } from './pages/error/error.component';
import { SimpleLayoutComponent } from './shared/layouts/simple-layout/simple-layout.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent,
  },

  {
    path: 'error-page',
    canActivate: [AuthGuard],
    component: SimpleLayoutComponent,
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
    ],
  },
  {
    path: 'access-denied',
    component: SimpleLayoutComponent,
    children: [
      {
        path: '',
        component: AccessDeniedComponent,
      },
    ],
  },

  {
    path: 'logout', // Rota para logout
    component: LogoutComponent,
  },

  {
    path: 'contrato-exp',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },

  {
    path: 'dashboards',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dashboards/dashboards.module').then(
        (m) => m.DashboardsModule
      ),
  },

  {
    path: '**',
    canActivate: [NotFoundGuard],
    children: [
      {
        path: 'contrato-exp',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
