import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private isDarkModeSubject = new BehaviorSubject<boolean>(null);
  isDarkMode$ = this.isDarkModeSubject.asObservable();

  constructor() {
    this.isDarkModeSubject.next(this.isDarkMode());
  }

  public isDarkMode(): boolean {
    let isDarkMode = false;
    const storedDarkMode = localStorage.getItem('darkMode');

    if (storedDarkMode) {
      try {
        const jsonDarkMode = JSON.parse(storedDarkMode);
        if (typeof jsonDarkMode !== 'boolean') {
          isDarkMode = false;
        } else {
          isDarkMode = jsonDarkMode;
        }
      } catch (error) {
        isDarkMode = false;
      }
    }

    return isDarkMode;
  }

  public ApplyTheme(): void {
    if (this.isDarkModeSubject.value) {
      document.documentElement.classList.add('dark-mode');
      document.documentElement.classList.remove('light-mode');
    } else {
      document.documentElement.classList.add('light-mode');
      document.documentElement.classList.remove('dark-mode');
    }
  }

  public toggleDarkMode(): void {
    const isDarkMode = this.isDarkModeSubject.value;
    if (!isDarkMode) {
      document.documentElement.classList.add('dark-mode');
      document.documentElement.classList.remove('light-mode');
    } else {
      document.documentElement.classList.add('light-mode');
      document.documentElement.classList.remove('dark-mode');
    }
    this.isDarkModeSubject.next(!isDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(!isDarkMode));
  }
}
