<app-group-box [title]="'FORM_INFO_BORDING_INSTRUCTIONS.BOARDING_INSTRUCTIONS' | translate"
  *ngIf="instrucoesEmbarqueToggle">
  <div class="row g-4">
    <div class="col-12 col-md-12 col-lg">
      <div class="row g-3">
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.INSTRUCTION_STATUS' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.INSTRUCTION_STATUS' | translate
              )
            }}" data-testid="statusInstrucao" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.statusInstrucao ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.INSTRUCTION_DATE' | translate
            }}</mat-label>
          <input placeholder="dd/mm/yyyy" disabled=true data-testid="datInstrucao" readonly class="input-form-dgs"
            type="text" [ngModel]="contrato.datInstrucao ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.TRANSPORTATION' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.TRANSPORTATION' | translate
              )
            }}" data-testid="meioTransp" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.meioTransp ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.PORT_OF_BOARDING' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.PORT_OF_BOARDING' | translate
              )
            }}" data-testid="portoEmb" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.portoEmb ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.DISCHARGE_PORT' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.DISCHARGE_PORT' | translate
              )
            }}" data-testid="portoDesc" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.portoDesc ?? ''" />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.MARITIME_AGENCY' | translate
            }}</mat-label>
          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.MARITIME_AGENCY' | translate
              )
            }}" data-testid="agMaritima" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.agMaritima ?? ''" />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.SHIPOWNER' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.SHIPOWNER' | translate
              )
            }}" data-testid="armador" readonly class="input-form-dgs" type="text" [ngModel]="contrato.armador ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.DELIVERY_PLACE' | translate
            }}</mat-label>
          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.DELIVERY_PLACE' | translate
              )
            }}" data-testid="localEntrega" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.localEntrega ?? ''" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.FINAL_DESTINATION' | translate
            }}</mat-label>

          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.FINAL_DESTINATION' | translate
              )
            }}" data-testid="destinoFinal" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.destinoFinal ?? ''" />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.SHIP_NAME' | translate
            }}</mat-label>
          <input disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.SHIP_NAME' | translate
              )
            }}" data-testid="nomNavio" readonly class="input-form-dgs" type="text"
            [ngModel]="contrato.nomNavio ?? ''" />
        </div>
      </div>

    </div>
    <div class="width-vr d-lg-block d-none">
      <app-divider orientation="V" />
    </div>
    <div class="d-lg-none">
      <app-divider orientation="H" />
    </div>
    <div class="col-12 col-md-12 col-lg">
      <div class="row g-3">
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.ARRIVAL_IN_BRAZIL' | translate
          }}</mat-label>
          <input
          disabled=true
            data-testid="chegadaBr"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.chegadaBr ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label 
          class="d-block lbl mb-1"
          [matTooltip]="
                'FORM_INFO_BORDING_INSTRUCTIONS.EXIT_FROM_BRAZIL_ETD_TOOLTIP'
                  | translate
              ">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.EXIT_FROM_BRAZIL_ETD' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            data-testid="saidaBr"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.saidaBr ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.STUFFING_PREDICTION' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            data-testid="prevEstufagem"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.prevEstufagem ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.TRIP_NUMBER' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.TRIP_NUMBER' | translate
              )
            }}"
            data-testid="viagemNum"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.viagemNum ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label 
          class="d-block lbl mb-1"
          >{{
            'FORM_INFO_BORDING_INSTRUCTIONS.DEADLINE_PREDICTION' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            data-testid="previsaoDead"
            class="input-form-dgs"
            type="text"
            readonly
            [ngModel]="contrato.previsaoDead ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label 
          class="d-block lbl mb-1"
          [matTooltip]="
          'FORM_INFO_BORDING_INSTRUCTIONS.ARRIVAL_DESTINATION_ETA_TOOLTIP'
            | translate
        "
          >{{
            'FORM_INFO_BORDING_INSTRUCTIONS.ARRIVAL_DESTINATION_ETA' | translate
          }}</mat-label>
          <input
          placeholder="dd/mm/yyyy"
          disabled=true
            data-testid="chegadaDest"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.chegadaDest ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'FORM_INFO_BORDING_INSTRUCTIONS.SHIPPING_ON_ACCOUNT' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_BORDING_INSTRUCTIONS.SHIPPING_ON_ACCOUNT' | translate
              )
            }}"
            data-testid="freteConta"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.freteConta ?? ''"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.COST_OF_FREIGHT' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.COST_OF_FREIGHT' | translate
              )
            }}"
            data-testid="valFrete"
            readonly
            class="input-form-dgs text-end"
            type="text"
            [ngModel]="contrato.valFrete ?? '' | localizedNumber"
          />
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.OBS_FREIGHT' | translate
          }}</mat-label>
          <textarea
          disabled="true"
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.OBS_FREIGHT' | translate
              )
            }}"
            data-testid="obsFrete"
            readonly
            class="input-form-dgs-textarea"
            type="text"
            [ngModel]="contrato.obsFrete ?? ''"
          ></textarea>
        </div>
        <div class="col-12">
          <mat-label class="d-block lbl mb-1">{{
            'COMMON.FORWARDING_AGENT' | translate
          }}</mat-label>
          <input
          disabled=true
            placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'COMMON.FORWARDING_AGENT' | translate
              )
            }}"
            data-testid="despachante"
            readonly
            class="input-form-dgs"
            type="text"
            [ngModel]="contrato.despachante ?? ''"
          />
        </div>
      </div>
    </div>
  </div>
</app-group-box>