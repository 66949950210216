import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-canal-banc-intermediario',
  templateUrl: './contrato-exp-modal-canal-banc-intermediario.component.html',
  styleUrls: ['./contrato-exp-modal-canal-banc-intermediario.component.css']
})
export class ContratoExpModalCanalBancIntermediarioComponent {
  @Input() contrato: Partial<IContrato> = {};
  faturamentoToggle = environment.faturamentoToggle;
  constructor (
    public functionsDateService: FunctionsDateService
  ) {
    
  }
}
