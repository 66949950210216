<app-loading [isLoading]="loading"></app-loading>

<div class="w-100">
  <div class="row gy-3 mb-3">
    <div class="col-lg-8 col-12 d-flex gap-0 align-items-center">
      <i class="fa-solid fa-handshake icon-hand-shake"></i>
      <span class="font-heading">{{
        'LAYOUT.TITLE_EXPORT_CONTRACTS' | translate
      }}</span>
    </div>

    <div
      class="col-lg-4 col-12 d-flex d-flex align-items-center justify-content-end container-pesquisa"
    >
      <mat-form-field appearance="fill" class="input-pesquisa">
        <input
          matInput
          [(ngModel)]="inputPesquisa"
          (ngModelChange)="aplicarFiltroPesquisa($event)"
          data-placeholder="{{ 'FORMS.SEARCH' | translate }}"
        />
        <span matSuffix>
          <i class="fa fa-search"></i>
        </span>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-4">
    <app-filtro-contratos
      *ngIf="filtroData && dataSource.data.length > 0"
      [contratos]="dataSource.data"
      (aplicarFiltro)="aplicarFiltros($event)"
    />
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="
        tableDragDropService.fixarTableDropped(displayedColumns, $event)
      "
      class="rounded-table full-width-table"
    >
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="d-flex justify-content-center align-items-center">
            <span
              data-testid="rowNotFound"
              class="column-footer"
              *ngIf="!loading"
            >
              {{
                'FORM_INFO_CONTRATO.CONTRACTS_NOT_FOUND' | translate | uppercase
              }}</span
            >
          </div>
        </td>
      </tr>

      <ng-container matColumnDef="Contrato">
        <th
          class="column-title"
          mat-header-cell
          width="12%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'CONTRACT_TABLE.CONTRACT' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.Contrato }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef class="p-1">
          <span class="column-footer" *ngIf="!loading"
            >{{ 'FOOTER_CONTRACT_TABLE.TOTAL_CONTRACTS' | translate }}{{ ' '
            }}{{ dataSource.filteredData.length }}</span
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Importador">
        <th
          class="column-title"
          mat-header-cell
          width="19%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'COMMON.IMPORTER' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.Importador }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="Exportador">
        <th
          class="column-title fixed-header"
          mat-header-cell
          width="19%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'CONTRACT_TABLE.EXPORTER' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.Exportador; else elseBlock">
            <span>{{ element.Exportador }}</span>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
        <ng-template #elseBlock>
          <span
            ><b>{{ 'COMMON.NOT_FOUND' | translate }} </b></span
          >
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="Cliente">
        <th
          class="column-title fixed-header"
          mat-header-cell
          width="15%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'CONTRACT_TABLE.CLIENT_REFERENCE' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.Cliente; else elseBlock">
            <span>{{ element.Cliente }}</span>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
        <ng-template #elseBlock>
          <span
            ><b>{{ 'COMMON.NOT_FOUND' | translate }} </b></span
          >
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="Embarque">
        <th
          class="column-title"
          mat-header-cell
          width="10%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'CONTRACT_TABLE.SHIPMENT_DATE' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.Embarque | translateMonthYear }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="Sacas">
        <th
          class="column-title"
          mat-header-cell
          width="12%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center gap-0">
            <div class="width-sacas">
              <app-icon-drag-drop />
              <span>{{ 'CONTRACT_TABLE.BAGS' | translate }}</span>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="width-sacas">
            <span>{{ element.Sacas | localizedNumber }}</span>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="p-1">
          <span class="column-footer" *ngIf="!loading">
            {{ dataTotalSacas?.label | translate }}
            {{
              dataTotalSacas?.totalSacas &&
                dataTotalSacas?.totalSacas >= 0 &&
                (dataTotalSacas?.totalSacas | localizedNumber)
            }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th
          class="column-title"
          mat-header-cell
          width="12%"
          *matHeaderCellDef
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <div class="d-flex justify-content-start align-items-center">
            <app-icon-drag-drop />
            {{ 'CONTRACT_TABLE.STATUS' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [matTooltip]="element.Status?.trim() | tooltipText | translate"
            [ngClass]="{
              'badge-dgs': true,
              'badge-dgs-danger': element.Status?.trim() === 'A EMBARCAR',
              'badge-dgs-success': element.Status?.trim() === 'EMBARCADO',
              'badge-dgs-info': element.Status?.trim() === 'EM TRÂNSITO',
              'badge-dgs-warning': element.Status?.trim() === 'ALFANDEGADO'
            }"
          >
            {{
              functionsTranslateService.getStatusTranslation(
                element.Status?.trim()
              ) | translate
            }}
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="Editar" *ngIf="dadosContratoToggle">
        <th
          mat-header-cell
          *matHeaderCellDef
          width="1%"
          cdkDrag
          cdkDragLockAxis="x"
          cdkDragBoundary="mat-header-row"
        >
          <span class="icon-edit d-flex justify-content-center">
            <i class="fa-solid fa-grip-vertical"></i>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <img
            id="modal-contrato"
            (click)="openModal(element.Handle)"
            src="/assets/edit-icon.svg"
            alt=""
          />
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let ctrrow; columns: displayedColumns"
        [style.border-bottom]="'1px solid var(--bg-card)'"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>

  <mat-paginator
    class="paginacao"
    [length]="dataSource.data.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Selecionar página"
    (page)="onPaginateChange($event)"
  >
  </mat-paginator>
</div>
