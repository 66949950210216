import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IVinculos } from 'src/app/auth/interfaces/Vinculos';
import { ControlAccessService } from '../../../auth/services/control-access.service';

@Injectable({
  providedIn: 'root',
})
export class VinculosService {
  private vinculoSubject: BehaviorSubject<IVinculos[]>;

  constructor(
    private http: HttpClient,
    private controlAccessService: ControlAccessService
  ) {
    this.vinculoSubject = new BehaviorSubject<IVinculos[]>([]);
  }

  fetchVinculos(
    nom_usuario: string,
    codEmpresa: number,
    tokenReq: string
  ): Observable<IVinculos[]> {
    const params = new HttpParams()
      .set('nom_usuario', nom_usuario)
      .set('codEmpresa', codEmpresa);

    const nomEmpresa = encodeURIComponent(localStorage.getItem('Empresa'));
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${tokenReq}`)
      .set('Empresa', nomEmpresa);

    return from(this.controlAccessService.getCompanyUrlBackend()).pipe(
      switchMap((base_url) => {
        const url = `${base_url}/api/vinculos`;

        return this.http
          .get<{
            success: boolean;
            message: string;
            data: IVinculos[];
          }>(url, { params, headers })
          .pipe(
            map((response) => {
              if (response.success) {
                return response.data;
              } else {
                throw new Error(response.message || 'Erro interno');
              }
            }),
            catchError((error: HttpErrorResponse) => {
              return throwError(() => error);
            })
          );
      })
    );
  }
}
