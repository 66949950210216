import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-dois-valor',
  templateUrl: './contrato-exp-modal-contrato-dois-valor.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-dois-valor.component.css'],
})
export class ContratoExpModalContratoDoisValorComponent {
  @Input() contrato: IContrato = {} as IContrato;

  constructor (
    public functionsDateService: FunctionsDateService
  ) {}
}
