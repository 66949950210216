<app-group-box [title]="'FORM_INFO_RESERVATION_CERTIFICATE.PLACE_RESERVATION' | translate">
  <div class="d-flex flex-column gap-2">
    <div class="row g-3">
      <div class="col-12 col-md-6 col-lg-3">
        <mat-label class="d-block lbl mb-1" [matTooltip]="
            'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_CNPJ_TOOLTIP'
              | translate
          ">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_CNPJ' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_CNPJ' | translate
            )
          }}" data-testid="cnpjReserva" readonly class="input-form-dgs" type="text" [ngModel]="contrato.cnpjReserva" />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.REQUEST_NUMBER' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.REQUEST_NUMBER' | translate
            )
          }}" data-testid="numSolicitacao" readonly class="input-form-dgs" type="text"
          [ngModel]="contrato.numSolicitacao" />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.AUTHORIZATION_NUMBER' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.AUTHORIZATION_NUMBER' | translate
            )
          }}" data-testid="numAutorizacao" readonly class="input-form-dgs" type="text"
          [ngModel]="contrato.numAutorizacao" />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <mat-label class="d-block lbl mb-1" [matTooltip]="
            'COMMON.DATE_FORMAT_TOOLTIP'
              | translate
          ">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_SUBMIT_DATE'
          | translate
          }}</mat-label>
        <input disabled=true data-testid="datEnvioReservaPraca" readonly class="input-form-dgs" type="text"
          placeholder="dd/mm/yyyy" [ngModel]="contrato.datEnvioReservaPraca" />
      </div>
      <div class="col-12">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_INSTRUCTIONS' | translate
          }}</mat-label>
        <div class="center-text-area">
          <textarea disabled=true placeholder="{{
              functionsDateService.capitalizeFirstLetter(
                'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_INSTRUCTIONS' | translate
              )
            }}" data-testid="instReserva" readonly class="input-form-dgs-textarea" type="text"
            [ngModel]="contrato.instReserva"></textarea>
        </div>
      </div>
      <div class="col-12">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_NUMBER' | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.BOOKING_NUMBER' | translate
            )
          }}" data-testid="numBooking" readonly class="input-form-dgs" type="text" [ngModel]="contrato.numBooking" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label class="d-block lbl mb-1" [matTooltip]="
            'COMMON.DATE_FORMAT_TOOLTIP'
              | translate
          ">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_DRAFT' | translate
          }}</mat-label>
        <input disabled=true data-testid="deadlineDraft" readonly class="input-form-dgs" type="text"
          placeholder="dd/mm/yyyy" [ngModel]="contrato.deadlineDraft" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label [matTooltip]="
            'COMMON.TIME_FORMAT_TOOLTIP'
              | translate
          " class="d-block lbl mb-1">{{
          'COMMON.TIME' | translate
          }}</mat-label>
        <input disabled=true data-testid="draftHora" readonly class="input-form-dgs" type="time"
          [ngModel]="contrato.draftHora" />
      </div>
      <div class="col-12 col-md-8 col-lg-8">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.CONTAINER_PICKUP_LOCATION'
          | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.CONTAINER_PICKUP_LOCATION' | translate
            )
          }}" data-testid="draftLocalRet" readonly class="input-form-dgs" type="text"
          [ngModel]="contrato.draftLocalRet" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label class="d-block lbl mb-1" [matTooltip]="
            'COMMON.DATE_FORMAT_TOOLTIP'
              | translate
          ">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_COFFEE' | translate
          }}</mat-label>
        <input disabled=true data-testid="deadlineCafe" readonly class="input-form-dgs" type="text"
          placeholder="dd/mm/yyyy" [ngModel]="contrato.deadlineCafe" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label [matTooltip]="
            'COMMON.TIME_FORMAT_TOOLTIP'
              | translate
          " class="d-block lbl mb-1">{{
          'COMMON.TIME' | translate
          }}</mat-label>
        <input disabled=true data-testid="cafeHora" readonly class="input-form-dgs" type="time"
          [ngModel]="contrato.cafeHora" />
      </div>
      <div class="col-12 col-md-8 col-lg-8">
        <mat-label class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.CONTAINER_DELIVERY_LOCATION'
          | translate
          }}</mat-label>
        <input disabled=true placeholder="{{
            functionsDateService.capitalizeFirstLetter(
              'FORM_INFO_RESERVATION_CERTIFICATE.CONTAINER_DELIVERY_LOCATION' | translate
            )
          }}" data-testid="cafeLocalEnt" readonly class="input-form-dgs" type="text"
          [ngModel]="contrato.cafeLocalEnt" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label class="d-block lbl mb-1" [matTooltip]="
            'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_VGM_TOOLTIP'
              | translate
          ">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_VGM' | translate
          }}</mat-label>
        <input disabled=true data-testid="deadLineVgm" readonly class="input-form-dgs" type="text"
          placeholder="dd/mm/yyyy" [ngModel]="contrato.deadLineVgm" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label [matTooltip]="
            'COMMON.TIME_FORMAT_TOOLTIP'
              | translate
          " class="d-block lbl mb-1">{{
          'COMMON.TIME' | translate
          }}</mat-label>
        <input disabled=true data-testid="vgmHora" readonly class="input-form-dgs" type="time"
          [ngModel]="contrato.vgmHora" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label [matTooltip]="
            'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_ISF_TOOLTIP'
              | translate
          " class="d-block lbl mb-1">{{
          'FORM_INFO_RESERVATION_CERTIFICATE.DEADLINE_ISF' | translate
          }}</mat-label>
        <input disabled=true data-testid="deadlineIsf" readonly class="input-form-dgs" type="text"
          placeholder="dd/mm/yyyy" [ngModel]="contrato.deadlineIsf" />
      </div>
      <div class="col-12 col-md-2 col-lg-2">
        <mat-label [matTooltip]="
            'COMMON.TIME_FORMAT_TOOLTIP'
              | translate
          " class="d-block lbl mb-1">{{
          'COMMON.TIME' | translate
          }}</mat-label>
        <input disabled=true data-testid="isfHora" readonly class="input-form-dgs" type="time"
          [ngModel]="contrato.isfHora" />
      </div>
    </div>
  </div>
</app-group-box>