<app-loading [isLoading]="loading"></app-loading>

<div class="container-login">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-9">
        <div class="login-wrapper row g-0">
          <div class="col-img col-md-6 col-lg-5 d-none d-md-block">
            <img
              src="../../assets/bgImgLogin.png"
              alt="login form"
              class="imgLogin"
              style="
                border-radius: 1rem 0 0 1rem;
                height: 100%;
                width: 100%;
                background-size: cover;
              "
            />
          </div>
          <div class="col-form col-md-6 col-lg-7 d-flex align-items-center">
            <div class="card-body p-4 p-lg-5 text-black">
              <form (keyup.enter)="login()">
                <div
                  class="d-flex align-items-center justify-content-left mb-5 pb-1"
                >
                  <mat-icon
                    aria-hidden="false"
                    aria-label="person"
                    fontIcon="person"
                  ></mat-icon>
                  <h1 class="ms-2 mb-0">Autenticação</h1>
                </div>
                <div>
                  <mat-form-field class="w-100">
                    <mat-label>Usuário</mat-label>
                    <span matPrefix><mat-icon>person</mat-icon></span>
                    <input
                      type="text"
                      [(ngModel)]="usuario"
                      matInput
                      name="usuario"
                    />
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Senha:</mat-label>
                    <span matPrefix><mat-icon>lock</mat-icon></span>
                    <input
                      matInput
                      [type]="hide ? 'password' : 'text'"
                      [(ngModel)]="senha"
                      name="senha"
                      (keydown.enter)="$event.preventDefault()"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      (click)="hide = !hide"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide"
                    >
                      <mat-icon>{{
                        hide ? 'visibility_off' : 'visibility'
                      }}</mat-icon>
                    </button>
                  </mat-form-field>

                  <div class="mb-3 d-flex justify-content-end">
                    <button mat-button (click)="login()" class="btn btn-login">
                      LOGIN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
