import { Component } from '@angular/core';

@Component({
  selector: 'shared-input-search-contract-by-nom-format',
  templateUrl: './input-search-contract-by-nom-format.component.html',
  styleUrls: ['./input-search-contract-by-nom-format.component.css'],
})
export class InputSearchContractByNomFormatComponent {
  constructor() {}
}
