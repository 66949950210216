import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SharedModule } from 'src/app/shared/shared.module';

import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContratoExpModalInstrucaoEmbarqueComponent } from './contrato-exp-modal-instrucao-embarque.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ContratoExpModalInstrucaoEmbarqueComponent,
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTooltipModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    DragDropModule,
  ],
  exports: [TranslateModule, ContratoExpModalInstrucaoEmbarqueComponent],
})
export class ContratoExpModalInstrucaoEmbarqueModule {}
