import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Material
import { AuthMaterialModule } from '../shared/material/auth-material.module';
import { SharedModule } from '../shared/shared.module';

//Components
import { HttpClientModule } from '@angular/common/http';
import { CallbackComponent } from '../auth/components/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { CallBackRedirectsService } from '../shared/services/CallBackRedirects/CallBackRedirects.service';

@NgModule({
  declarations: [LoginComponent, LogoutComponent, CallbackComponent],
  imports: [HttpClientModule, AuthMaterialModule, SharedModule, CommonModule],
  providers: [CallBackRedirectsService],
})
export class AuthModule {}
