import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-contrato-um-info',
  templateUrl: './contrato-exp-modal-contrato-um-info.component.html',
  styleUrls: ['./contrato-exp-modal-contrato-um-info.component.css']
})
export class ContratoExpModalContratoUmInfoComponent {
  @Input() contrato: IContrato = {} as IContrato;

  constructor
  (public functionsDateService: FunctionsDateService) {}
}
