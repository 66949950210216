import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-faturamento-condicoes',
  templateUrl: './contrato-exp-modal-faturamento-condicoes.component.html',
  styleUrls: ['./contrato-exp-modal-faturamento-condicoes.component.css'],
})
export class ContratoExpModalFaturamentoCondicoesComponent {
  @Input() contrato: IContrato = {} as IContrato;
  constructor (
    public functionsDateService: FunctionsDateService
  ) {}

}
