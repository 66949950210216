import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  @Output() sidebarVisibleChanged: EventEmitter<boolean> = new EventEmitter();
  @Input() sidebarVisible: boolean;
  @Input() expanded: boolean;

  @Input() darkMode: boolean;
  currentUrl: string;

  logoToggle = environment.logoToggle;
  isMobile: boolean = false;

  constructor(
    public router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.currentUrl = this.router.url;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sidebarVisible']) {
      const navVisible = changes['sidebarVisible'].currentValue;
      if (navVisible) this.expanded = false;
    }
  }

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((breakpoint) => {
        this.isMobile =
          (breakpoint.matches &&
            this.breakpointObserver.isMatched(Breakpoints.Small)) ||
          this.breakpointObserver.isMatched(Breakpoints.XSmall);
        if (this.isMobile) this.sidebarVisibleChanged.emit(false);
        this.expanded = false;
      });
  }

  hoverSidebar(expanded, isMobile) {
    if (isMobile) return;
    this.expanded = !expanded;
  }
}
