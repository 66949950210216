<app-group-box [title]="'COMMON.FINAL_VALUE' | translate">
  <div class="row g-3">
    <div class="col-lg-2 col-md-4 col-sm-6">
      <mat-label class="d-block lbl lbl-status-emb m-1">{{
        'FORM_INFO_CONTRATO_DOIS.UNIT_PRICE' | translate
        }}</mat-label>
      <input disabled=true disabled=true placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.UNIT_PRICE' | translate
          )
        }}" readonly class="input-form-dgs text-end" type="text" data-testid="valorUnitario" name="valorUnitario"
        [ngModel]="contrato.valorUnitario ?? 0 | localizedNumber" />
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class="d-block lbl lbl-status-emb m-1">{{
        'FORM_INFO_CONTRATO_DOIS.UNIT_WEIGHT' | translate
        }}</mat-label>
      <input disabled=true disabled=true placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.UNIT_WEIGHT' | translate
          )
        }}" readonly class="input-form-dgs" type="text" data-testid="unidadePrecoDolar" name="unidadePrecoDolar"
        [ngModel]="contrato.unidadePrecoDolar ?? 0" />
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6">
      <mat-label class="d-block lbl lbl-status-emb m-1">{{
        'FORM_INFO_CONTRATO_DOIS.TOTAL_VALUE_IN_DOLAR' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
          functionsDateService.capitalizeFirstLetter(
            'FORM_INFO_CONTRATO_DOIS.TOTAL_VALUE_IN_DOLAR' | translate
          )
        }}" readonly class="input-form-dgs text-end" type="text" data-testid="valorTotalDolar" name="valorTotalDolar"
        [ngModel]="contrato.valorTotalDolar ?? 0 | localizedNumber" />
    </div>
  </div>
</app-group-box>