import { inject, Injectable } from '@angular/core';
import { IListContratos } from 'src/app/interfaces/ListContratos';
import { NgxTranslateService } from 'src/app/shared/services/translate/ngxTranslate.service';
import { FunctionsTranslateService } from 'src/app/shared/utils/functions.translate.service';

export type FiltrosContratos = {
  pesquisa: string;
  status: string;
  data_embarque_inicial: Date;
  data_embarque_final: Date;
  importadores: string;
};

@Injectable({
  providedIn: 'root',
})
export class ContratosFiltrosService {
  private filtros: FiltrosContratos = {} as FiltrosContratos;
  private translateService = inject(NgxTranslateService);
  private functionsTranslate = inject(FunctionsTranslateService);

  getFiltros(): FiltrosContratos {
    return this.filtros;
  }

  mudarFiltro(chave: keyof FiltrosContratos, valor: any): void {
    this.filtros = { ...this.filtros, [chave]: valor };
  }

  public retornarFilterPredicateContratos(
    data: IListContratos,
    filter: string
  ) {
    const parsedFilter = JSON.parse(filter.toLowerCase()) as FiltrosContratos;
    const pesquisaGlobal = !parsedFilter.pesquisa
      ? true
      : data.Contrato.toLowerCase().includes(parsedFilter.pesquisa) ||
        data.Cliente.toLowerCase().includes(parsedFilter.pesquisa) ||
        data.Importador.toLowerCase().includes(parsedFilter.pesquisa) ||
        data.Exportador.toLowerCase().includes(parsedFilter.pesquisa) ||
        data.Sacas.toLowerCase().includes(parsedFilter.pesquisa) ||
        this.retornarStatusEmbarquePosTraducao(data.Status)
          .toLowerCase()
          .includes(parsedFilter.pesquisa) ||
        this.retornarDataEmbarquePosTraducao(data.Embarque)
          .toLowerCase()
          .includes(parsedFilter.pesquisa);

    const pesquisaStatusEmbarque = !parsedFilter.status
      ? true
      : parsedFilter.status.includes(data.Status.toLowerCase());

    const pesquisaImportadores = !parsedFilter.importadores
      ? true
      : parsedFilter.importadores.includes(data.Importador.toLowerCase());

    const pesquisaDataEmbarque =
      parsedFilter.data_embarque_inicial && parsedFilter.data_embarque_final
        ? data._DataEmbarque >= new Date(parsedFilter.data_embarque_inicial) &&
          data._DataEmbarque <= new Date(parsedFilter.data_embarque_final)
        : true;
    return (
      pesquisaGlobal &&
      pesquisaStatusEmbarque &&
      pesquisaDataEmbarque &&
      pesquisaImportadores
    );
  }

  public retornarDataEmbarquePosTraducao(dataEmbarque: string): string {
    const dataEmbarqueSplit = dataEmbarque.split('/');
    const mesTraduzido = this.translateService.instant(
      `MONTH.${dataEmbarqueSplit[0]}`
    );
    return `${mesTraduzido}/${dataEmbarqueSplit[1]}`;
  }

  public retornarStatusEmbarquePosTraducao(statusEmbarque: string): string {
    const stringTraducao =
      this.functionsTranslate.getStatusTranslation(statusEmbarque);
    return this.translateService.instant(stringTraducao);
  }

  public limparFiltros() {
    delete this.filtros.data_embarque_inicial;
    delete this.filtros.data_embarque_final;
    delete this.filtros.status;
    delete this.filtros.importadores;
  }
}
