import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import * as jose from 'jose';
import {
  catchError,
  concatMap,
  EMPTY,
  firstValueFrom,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { HTTPCode } from 'src/app/enums/HTTPCode';
import { StorageConstants } from 'src/app/shared/constants';
import { CallBackRedirectsService } from 'src/app/shared/services/CallBackRedirects/CallBackRedirects.service';
import { VinculosService } from 'src/app/shared/services/Permissoes/vinculos.service';
import { TokenProviderService } from 'src/app/shared/services/TokenProvider/token-provider.service';
import { IUsuarioInfo } from '../../interfaces/UsuarioInfo';
import { IVinculos } from '../../interfaces/Vinculos';
import { AuthService } from '../../services/auth.service';
import { ControlAccessService } from '../../services/control-access.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css'],
})
export class CallbackComponent implements OnInit {
  userAtributtes: IUsuarioInfo | null = null;
  userPermissions: any;
  loading: boolean = false;
  orchestratorToken: string;

  constructor(
    private authService: AuthService,
    private tokenService: TokenProviderService,
    private userService: UserService,
    private controlAccessService: ControlAccessService,
    private vinculosService: VinculosService,
    private activatedRoute: ActivatedRoute,
    private callBackRedirectsService: CallBackRedirectsService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true;
      const fragment = await firstValueFrom(this.activatedRoute.fragment);
      const fragmentParams = new URLSearchParams(fragment ?? '');
      const idToken = fragmentParams.get('id_token');
      this.orchestratorToken = await this.tokenService.setTokenOrchestrator();
      this.authService.setAuthToken(
        StorageConstants.ORCHESTRATOR_TOKEN,
        this.orchestratorToken
      );

      if (this.validateIdToken(idToken)) {
        this.userAtributtes = this.getUserPoolParams(idToken ?? '');

        const initialToken = await this.authService.generateAuthToken(
          this.userAtributtes
        );

        this.authService.setAuthToken('token', initialToken);

        const responsePermissions = await this.getUserPermissions(
          environment.local
            ? environment.usuarioLocal
            : this.userAtributtes.nome
        );

        this.getVinculos(
          environment.local
            ? environment.usuarioLocal
            : this.userAtributtes.nome,
          1,
          this.orchestratorToken
        )
          .pipe(
            concatMap(async () => {
              if ('redirectStatus' in responsePermissions) {
                if (
                  responsePermissions['redirectStatus'] === HTTPCode.NOT_FOUND
                ) {
                  this.callBackRedirectsService.accessDeniedRedirect();
                  return EMPTY;
                }
              }

              this.getLicences(responsePermissions[0].nomEmpresa);

              this.userPermissions = responsePermissions;
              this.userAtributtes.permissoes = this.userPermissions;

              const token = await this.authService.generateAuthToken(
                this.userAtributtes
              );
              this.authService.setAuthToken('token', token);

              const authToken = this.authService.getAuthToken();

              if (authToken != null) {
                this.callBackRedirectsService.successRedirect();
              } else {
                this.callBackRedirectsService.loginRedirect();
              }
              return of();
            })
          )
          .subscribe({
            error: (err) => {
              console.log('Vinculos nao encontrados!', err);
              //this.callBackRedirectsService.accessDeniedRedirect();
            },
          });
      } else {
        this.callBackRedirectsService.loginRedirect();
        this.loading = false;
      }
    } catch (error) {
      this.callBackRedirectsService.loginRedirect();
      this.loading = false;
    }
  }

  getUserPoolParams(token: string): IUsuarioInfo {
    try {
      const parts = token.split('.');

      const base64UrlPayload = parts[1];

      const base64Payload = base64UrlPayload
        .replace(/-/g, '+')
        .replace(/_/g, '/');

      const jsonPayload = atob(base64Payload);

      const decodedPayload = JSON.parse(jsonPayload);

      const userAtributtes: IUsuarioInfo = {
        empresa: decodedPayload['custom:company_name'],
        nome: decodedPayload['name'],
        email: decodedPayload['email'],
        telefone: decodedPayload['phone_number'],
        url_api: decodedPayload['custom:URL_api'],
      };

      environment.local
        ? localStorage.setItem('Empresa', environment.empresaLocal)
        : localStorage.setItem('Empresa', userAtributtes.empresa);

      this.userService.setUserLogged('userName', userAtributtes.nome);
      return userAtributtes;
    } catch (error) {
      this.callBackRedirectsService.loginRedirect();
      this.loading = false;
      throw new Error('Erro na decodificação do token');
    }
  }

  encodeCompanyName(company: string): Promise<string> {
    const secret = jose.base64url.decode(environment.secret);
    const payload = { empresa: company };

    return new jose.EncryptJWT(payload)
      .setProtectedHeader({ alg: 'dir', enc: 'A128CBC-HS256' })
      .setIssuedAt()
      .encrypt(secret)
      .then((jwt) => jwt)
      .catch((error) => {
        console.error('Erro ao codificar o nome da empresa:', error);
        throw new Error('Erro ao codificar o nome da empresa');
      });
  }

  validateIdToken(idToken: string) {
    if (idToken != null) {
      return true;
    } else {
      return false;
    }
  }

  async getUserPermissions(userName: string | null): Promise<any> {
    if (typeof userName !== 'string') throw new Error();
    try {
      const result = await firstValueFrom(
        this.controlAccessService.getUserPermissions(userName).pipe(
          map((response) => {
            if (response.success === true) return response.data;
              throw new Error(response.message ?? 'Erro interno');
          }),
          catchError((error: HttpErrorResponse) => {
            this.loading = false;
            console.log(error)
            // return throwError(() => error);
            return of([])
          })
        )
      );

      return result;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === HTTPCode.NOT_FOUND) {
          return { redirectStatus: HTTPCode.NOT_FOUND };
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  getLicences(nomEmpresa: string): void {
    this.controlAccessService
      .getLicenca(nomEmpresa, this.orchestratorToken)
      .subscribe({
        error: (error) => {
          if (error.status === 404) {
            this.callBackRedirectsService.accessDeniedRedirect();
            return;
          }
        },
      });
  }

  getVinculos(
    nom_usuario: string,
    codEmpresa: number,
    tokenReq: string
  ): Observable<void | IVinculos[]> {
    return this.vinculosService
      .fetchVinculos(nom_usuario, codEmpresa, tokenReq)
      .pipe(
        tap((listaVinculos) => {
          this.userAtributtes.vinculos = listaVinculos;
          this.userAtributtes.tipo = listaVinculos[0].tp_usuario;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.userAtributtes.tipo = 'C';
            return of([]);
          }
          return throwError(error);
        })
      );
  }
}
