<!-- <app-loading [isLoading]="loading"></app-loading> -->

<script src="https://cdnjs.cloudflare.com/ajax/libs/Sortable/1.14.0/Sortable.min.js"></script>

<div class="content-wrapper p-0 vh-100">
  <div class="d-flex">
    <nav class="navbar navbar-expand-lg navbar-light w-100 mb-0">
      <div class="container-fluid d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-center h-100">
          <img src="/assets/d_logobranca.png" class="logo-navbar" alt="" />
        </div>
      </div>
    </nav>
  </div>

  <main class="w-100 p-3 d-flex flex-column">
    <div class="card-content p-4 flex-grow-1">
      <router-outlet>
        <div class="app-sidenav-content">
          <ng-content></ng-content>
        </div>
      </router-outlet>
    </div>
  </main>
  <div class="w-100 px-3">
    <app-footer></app-footer>
  </div>
</div>
