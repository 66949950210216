import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { catchError, combineLatest, from, map, of, switchMap, tap, throwError } from 'rxjs';
import { IVinculos } from 'src/app/auth/interfaces/Vinculos';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MenuModules } from 'src/app/enums/MenuModules';
import { IFiliais } from 'src/app/interfaces/Filiais';
import { FiliaisService } from 'src/app/shared/services/Filiais/filiais.service';
import { CallBackRedirectsService } from '../../services/CallBackRedirects/CallBackRedirects.service';
import { VinculosService } from '../../services/Permissoes/vinculos.service';
import { PermissoesFiliaisService } from '../../services/PermissoesFiliais/permissoes-filiais.service';
import { ThemeService } from '../../services/Theme/theme.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent {
  NoBorderContainer = false;

  filiais: IFiliais[] = [];
  selectedFilial: IFiliais;

  sideNavToggle = environment.sideNavToggle;
  whatsappToggle = environment.whatsappToggle;

  darkMode: boolean;
  isDropdownOpen: boolean = false;
  permissionsPerFilial: any = [];
  avaiableModules: any = [];
  loading: boolean = false;
  expanded: boolean;
  modules = MenuModules;
  sidebarVisible: boolean;

  constructor(
    private readonly themeService: ThemeService,
    private readonly filiaisService: FiliaisService,
    private readonly vinculosService: VinculosService,
    private readonly authService: AuthService,
    private readonly permissoesFiliais: PermissoesFiliaisService,
    private readonly callbackRedirectsService: CallBackRedirectsService,
    private readonly route: ActivatedRoute
  ) {
    this.themeService.ApplyTheme();
    this.themeService.isDarkMode$.subscribe(
      (isDarkMode) => (this.darkMode = isDarkMode)
    );

    this.expanded = false;
    this.sidebarVisible = true;

    this.route.data.subscribe((data: { NoBorderContainer?: boolean }) => {
      const noBorder = data['NoBorderContainer'] ?? false;
      this.NoBorderContainer = noBorder;
    });
  }

  sidebarVisibleChanged(event) {
    setTimeout(() => {
      this.sidebarVisible = event;
    }, 10);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  filialChanged(filial: IFiliais) {
    this.filiaisService.setFilialSelecionada(filial);
  }

  carregarFiliais() {
    this.loading = true;
    combineLatest([
      this.filiaisService.getAllFiliais(),
      this.filiaisService.getFilialSelecionada(),
      this.permissoesFiliais.getPermissoes(),
      this.carregarVinculos(),
    ]).subscribe({
      next: ([filiais, filialSelecionada]) => {
        this.filiais = filiais;
        this.selectedFilial = filialSelecionada;
        this.loading = false;
      },
      error: () => this.callbackRedirectsService.errorRedirect(),
    });
  }

  carregarVinculos() {
    this.loading = true;
    const token = this.authService.getAuthToken();
    const orchestratorToken = this.authService.getOrchestratorToken();
    const userAttributesPromise = this.authService.decodeToken(token);
    const userAttributes$ = from(userAttributesPromise);

    return userAttributes$.pipe(
      switchMap((userAttributes) => {
        if (!userAttributes) return of(null);
        return this.vinculosService
          .fetchVinculos(userAttributes.nome, 1, orchestratorToken)
          .pipe(
            map((vinculos: IVinculos[]) =>
              vinculos.length > 0 ? vinculos : null
            ),
            tap((vinculos: IVinculos[]) => {
              userAttributes.vinculos = vinculos;
              userAttributes.tipo = vinculos[0].tp_usuario;
              const newTokenPromise =
                this.authService.generateAuthToken(userAttributes);
              const newToken$ = from(newTokenPromise);

              newToken$
                .pipe(
                  tap((newToken) => {
                    this.authService.setAuthToken('token', newToken);
                  })
                )
                .subscribe();
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                userAttributes.tipo = 'C';
                return of([]);
              }
              return throwError(error);
            })
          );
      })
    );
  }

  ngOnInit() {
    this.carregarFiliais();
  }

  getCardContentMaxWidth(): string {
    return this.sidebarVisible ? (this.expanded ? '100%' : '100%') : '100%';
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
    this.expanded = !this.expanded;
  }

  expandSidebar() {
    this.expanded = true;
  }

  collapseSidebar() {
    this.expanded = false;
  }
}
