import { Component, inject, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/Theme/theme.service';

@Component({
  selector: 'app-button-toggle-theme',
  templateUrl: './button-toggle-theme.component.html',
  styleUrls: ['./button-toggle-theme.component.css'],
})
export class ButtonToggleThemeComponent implements OnInit {
  public isDarkMode: boolean;

  private themeService = inject(ThemeService);

  ngOnInit(): void {
    this.themeService.isDarkMode$.subscribe(
      (isDarkMode) => (this.isDarkMode = isDarkMode)
    );
  }

  toggleDarkMode() {
    const button = document.querySelector('.btn-toggle-dark-mode');
    button.classList.toggle('rotate');
    this.themeService.toggleDarkMode();
  }
}
