<div class="row g-4">
  <div class="row g-3">
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.BENEFICIARY_BANK' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.BENEFICIARY_BANK' | translate
                )
              }}" class="input-form-dgs" type="text"  data-testid="benefBank" readonly [ngModel]="contrato.benefBank" />
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.SWIFT_CODE' | translate
        }}</mat-label>

      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.SWIFT_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" data-testid="swiftCodeBenef" readonly [ngModel]="contrato.swiftCodeBenef" />
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.ABA_CODE' | translate
        }}</mat-label>

      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.ABA_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="abaCodeBenef" [ngModel]="contrato.abaCodeBenef" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.IBAN_CODE' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.IBAN_CODE' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="ibanCodeBenef" [ngModel]="contrato.ibanCodeBenef" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.AGENCY' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.AGENCY' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="nomAgenciaBenef" [ngModel]="contrato.nomAgenciaBenef" />
    </div>
    <div class="col-12">
      <mat-label class="d-block lbl mb-1">{{
        'COMMON.CHECKING_ACCOUNT' | translate
        }}</mat-label>
      <input disabled=true placeholder="{{
                functionsDateService.capitalizeFirstLetter(
                  'COMMON.CHECKING_ACCOUNT' | translate
                )
              }}" class="input-form-dgs" type="text" readonly data-testid="contaCorrenteBenef" [ngModel]="contrato.contaCorrenteBenef" />
    </div>
  </div>
</div>