import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContratoExpModalReservaCertificadoPracaComponent } from './components/contrato-exp-modal-reserva-certificado-praca/contrato-exp-modal-reserva-certificado-praca.component';
import { ContratoExpModalReservaCertificadoComponent } from './contrato-exp-modal-reserva-certificado.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContratoExpModalReservaCertificadoCertificadosComponent } from './components/contrato-exp-modal-reserva-certificado-certificados/contrato-exp-modal-reserva-certificado-certificados.component';

@NgModule({
  declarations: [
    ContratoExpModalReservaCertificadoComponent,
    ContratoExpModalReservaCertificadoPracaComponent,
    ContratoExpModalReservaCertificadoCertificadosComponent,
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    CommonModule,
    TranslateModule,
    MatTableModule,
    DragDropModule,
    SharedModule,
  ],
  exports: [TranslateModule, ContratoExpModalReservaCertificadoComponent],
})
export class ContratoExpModalReservaCertificadoModule {}
