import { Component, Input } from '@angular/core';
import { IContrato } from 'src/app/interfaces/ContratoExp';
import { FunctionsDateService } from 'src/app/shared/utils/functions.date.service';

@Component({
  selector: 'app-contrato-exp-modal-canal-banc-beneficiario',
  templateUrl: './contrato-exp-modal-canal-banc-beneficiario.component.html',
  styleUrls: ['./contrato-exp-modal-canal-banc-beneficiario.component.css']
})
export class ContratoExpModalCanalBancBeneficiarioComponent {
  @Input() contrato: Partial<IContrato> = {};
  constructor (
    public functionsDateService: FunctionsDateService
  ) {}
}



