<div *ngIf="isLoading" class="loading-overlay">
  <div class="loading-spinner">
    <ng-container *ngIf="darkMode">
      <img src="/assets/d_logoazul.png" height="70" alt="">
    </ng-container>
    <ng-container *ngIf="!darkMode">
      <img src="/assets/d_logobranca.png" height="70" alt="">
    </ng-container>
  </div>
</div>

