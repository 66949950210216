import { DOCUMENT } from '@angular/common';
import { Component, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css'],
})
export class AccessDeniedComponent {
  private router = inject(Router);
  private authService = inject(AuthService);
  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);

  ngAfterViewInit() {
    const toasts = this.document.querySelectorAll(
      '.toastify.on.toastify-right.toastify-top'
    );
    toasts.forEach((toast) =>
      this.renderer.removeChild(toast.parentNode, toast)
    );
  }

  public redirectToLogin() {
    this.authService.removeAuthToken();
    this.router.navigate(['/']);
  }
}
