import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContratosService } from '../services/contrato-exp.service';
import { IListContratos } from 'src/app/interfaces/ListContratos';
import { FiliaisService } from 'src/app/shared/services/Filiais/filiais.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { ContratosEditModalComponent } from '../contrato-exp-modal/contrato-exp-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TableDragDropService } from 'src/app/shared/services/TableDragDrop/tableDragDrop.service';
import { IResponseTotalizarSacas } from './interfaces/ResponseTotalizarSacas';
import { ContratosFiltrosService } from '../services/contrato-exp-filtros.service';
import { FunctionsTranslateService } from 'src/app/shared/utils/functions.translate.service';
import { IContrato } from 'src/app/interfaces/ContratoExp';

@Component({
  selector: 'app-contrato-exp-view',
  templateUrl: './contrato-exp-view.component.html',
  styleUrls: ['./contrato-exp-view.component.css'],
})
export class ContratoExpViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public loading: boolean = false;
  public dataSource: MatTableDataSource<IListContratos>;
  public displayedColumns: string[];
  public dataTotalSacas: IResponseTotalizarSacas;
  private subscription$: Subscription = new Subscription();
  private _inputPesquisa: string = '';

  get inputPesquisa(): string {
    return this._inputPesquisa;
  }

  set inputPesquisa(valor: string) {
    this._inputPesquisa = valor;
    this.aplicarFiltroPesquisa(valor);
  }

  public dadosContratoToggle = environment.dadosContratoToggle;
  public filtroData: boolean = environment.filtroData;

  constructor(
    private filiaisService: FiliaisService,
    private contratosService: ContratosService,
    public tableDragDropService: TableDragDropService,
    public functionsTranslateService: FunctionsTranslateService,
    private contratosFiltrosService: ContratosFiltrosService,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) {
    this.openModal = this.openModal.bind(this);
    this.dataSource = new MatTableDataSource<IListContratos>();
  }

  ngAfterViewInit(): void {
    if (this.dataSource && this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnInit(): void {
    this.buscarContratos();
    this.dataSource.filterPredicate = (data: IListContratos, filter) =>
      this.contratosFiltrosService.retornarFilterPredicateContratos(
        data,
        filter
      );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  filtrarContratos(codFilial: number): void {
    if (codFilial) {
      this.loading = true;
      const contratoSub = this.contratosService
        .fetchListContratos(codFilial)
        .subscribe((data: any[]) => {
          const contratosList: IListContratos[] = data.map((item) => ({
            Handle: item.handle,
            Contrato: item.contrato,
            Cliente: item.referenciaCli,
            Importador: item.importador,
            Exportador: item.exportador,
            Embarque: item.embarque,
            Sacas: item.sacas,
            Status: item.status,
            _DataEmbarque: item.dataEmbarque,
          }));
          this.dataSource.data = contratosList;
          this.resetarFiltros();
          this.resetarPaginator();
          this.totalizarSacas();
          this.loading = false;
        });
      this.subscription$.add(contratoSub);
    }
  }

  public buscarContratos() {
    this.loading = true;
    const filialSub = this.filiaisService.getFilialSelecionada().subscribe({
      next: (filial) => {
        this.loading = true;
        this.filtrarContratos(filial?.codFilial);
      },
      error: () => {
        this.loading = false;
      },
    });
    this.subscription$.add(filialSub);
    const columns = [
      'Contrato',
      'Importador',
      'Exportador',
      'Cliente',
      'Embarque',
      'Sacas',
      'Status',
    ];
    if (this.dadosContratoToggle) columns.push('Editar');
    this.displayedColumns = columns;
  }

  private resetarFiltros() {
    this.inputPesquisa = '';
    this.dataSource.filter = '';
  }

  aplicarFiltros(filtros: string) {
    this.dataSource.filter = filtros;
    this.totalizarSacas();
  }

  aplicarFiltroPesquisa(valor: string) {
    const filterValue = valor;
    this.contratosFiltrosService.mudarFiltro(
      'pesquisa',
      filterValue.trim().toLowerCase()
    );
    this.dataSource.filter = JSON.stringify(
      this.contratosFiltrosService.getFiltros()
    );
    this.totalizarSacas();
  }

  onPaginateChange(event) {
    if (this.dataSource.paginator) {
      this.dataSource.paginator.pageIndex = event.pageIndex;
      this.dataSource.paginator.pageSize = event.pageSize;
      this.dataSource.paginator.length = event.length;
    }
  }

  public openModal(info: IContrato): void {
    const buttonsRemoveClass =
      document.getElementsByClassName('btn-filtro-aux');
    this.adicionarRemoverClass(buttonsRemoveClass, true);

    this.dialog.open(ContratosEditModalComponent, {
      data: info,
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '100%',
      width: '100%',
    });

    this.dialog.afterAllClosed.subscribe(() => {
      const buttonsAddClass = document.getElementsByClassName('btn-filtro-aux');
      this.adicionarRemoverClass(buttonsAddClass, false);
    });
  }

  public totalizarSacas() {
    this.dataTotalSacas = this.contratosService.totalizarSacas(this.dataSource);
  }

  private resetarPaginator() {
    if (this.dataSource && this.paginator) {
      this.paginator.firstPage();
    }
  }

  adicionarRemoverClass(buttons: HTMLCollectionOf<Element>, remover: boolean) {
    Array.from(buttons).forEach((button: Element) => {
      remover === true
        ? this.renderer.removeClass(button, 'button-contratos-filtros')
        : this.renderer.addClass(button, 'button-contratos-filtros');
    });
  }
}
